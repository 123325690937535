import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";
import { SurveyRespondent } from "../model/survey-respondent";
import { SurveySubmit } from "../model/survey-submit";

export function getSurveyRespondents(): Promise<SurveyRespondent[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/list`, requestOptions)
        .then(response => {
            return handleResponse<SurveyRespondent[]>(response)
        });
}

export function getSurveyRespondent(surveyRespondentId: number): Promise<SurveyRespondent> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/${surveyRespondentId}`, requestOptions)
        .then(response => {
            return handleResponse<SurveyRespondent>(response)
        });
}

export function getSurveyRespondentSubmits(surveyRespondentId: number): Promise<SurveySubmit[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/respondent/${surveyRespondentId}/submit/list`, requestOptions)
        .then(response => {
            return handleResponse<SurveySubmit[]>(response)
        });
}