import React, { useCallback } from "react";
import { ModuleMultiSelectFilterModel } from "./module-multi-select-filter-model";
import { moduleMultiSelectFilterFieldDefinitions } from "./module-multi-select-filter-field-definition";
import { FieldDefinition } from "../../../form/field-definition";
import BaseForm from "../../../form/base-form";

export default function ModuleMultiSelectFilter({
    filter,
    setFilter,
    hidden
}: {
    filter: ModuleMultiSelectFilterModel,
    setFilter: React.Dispatch<React.SetStateAction<ModuleMultiSelectFilterModel>>
    hidden?: boolean | undefined
}) {
    const handleFilter = useCallback((filterUpdate: ModuleMultiSelectFilterModel) => {
        setFilter(filterUpdate)
    }, [setFilter])

    const getFieldDefinitions = (): FieldDefinition[] => {
        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "courseLevel")!.hidden = filter.hideModuleSearch === true;
        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "courseLevel")!.disabled = filter.lockCourseLevel === true;

        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "moduleCategory")!.hidden = filter.hideModuleCategory === true;
        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "moduleCategory")!.disabled = filter.lockModuleCategory === true;

        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "moduleSearch")!.hidden = filter.hideModuleSearch === true;
        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "moduleSearch")!.disabled = filter.lockModuleSearch === true;

        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "modulesSelected")!.hidden = filter.hideModulesSelected === true;
        moduleMultiSelectFilterFieldDefinitions.find(_ => _.id === "modulesSelected")!.disabled = filter.lockModulesSelected === true;

        return moduleMultiSelectFilterFieldDefinitions;
    }

    return (
        <React.Fragment>
            {(hidden ?? false) && <span></span>}
            {(hidden ?? false) === false && <BaseForm
                model={filter}
                setModel={(e) => handleFilter(e)}
                fields={getFieldDefinitions()}
            />}
        </React.Fragment>
    );
}