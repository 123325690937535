import { FieldDefinition } from "../../../../component/form/field-definition";

export const surveyQuestionAnswerFieldDefinitions: FieldDefinition[] = [
    {
        id: "text",
        name: "Answer option",
        type: "text",
        required: true,
        disabled: false,
    }
];