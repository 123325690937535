import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { badgeFieldDefinitions } from "./badge-field-definition";
import { Badge, badgeDisplayName } from "../../../model/badge";
import styles from "../../../../../section/style/crud-control.module.css"
import { useToast } from "../../../../../context/toast/toast-provider";
import { addBadge, deleteBadge, updateBadge } from "../../../service/badge-service";
import { EDUCATION_MANAGEMENT_MODULE_BADGE_ADD, EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT, EDUCATION_MANAGEMENT_MODULE_EDIT, PARAMETER_BADGE_ID, PARAMETER_MODULE_ID } from "../../../../../infrastructure/route";
import { getModuleCohorts } from "../../../service/module-service";
import AddIcon from '@mui/icons-material/Add';
import { FieldDefinition } from "../../../../../component/form/field-definition";
import BaseForm from "../../../../../component/form/base-form";
import React from "react";
import { Button } from "@mui/material";
import { BADGE_TAB_STATE } from "../badge-view";

export default function BadgeHandler({
    newModel,
    badge,
    setBadge
}: {
    newModel: boolean,
    badge: Badge,
    setBadge: React.Dispatch<React.SetStateAction<Badge>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const location = useLocation();
    const toast = useToast();
    const navigate = useNavigate();

    const badges = (location.state !== null ? location.state : []) as Badge[];

    const openAdd = () => {
        setBadge({
            moduleId: badge.moduleId,
            badgeId: "",
            title: "",
            instruction: "",
            badgeFolder: "",
            active: true,
            sequence: badges.length === 0 ? 1 : Math.max(...badges.map(_ => _.sequence)) + 1,
            estimatedTime: 1,
            allowResubmit: false
        });
        localStorage.removeItem(BADGE_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_MODULE_BADGE_ADD
            .replace(PARAMETER_MODULE_ID, `${badge.moduleId}`), { state: badges });
    }

    const getFieldDefinitions = (): FieldDefinition[] => {
        if (!newModel) {
            badgeFieldDefinitions.find(_ => _.id === "sequence")!.disabled = true;
        } else {
            badgeFieldDefinitions.find(_ => _.id === "sequence")!.disabled = false;
        }

        return badgeFieldDefinitions;
    }

    const calculateBadgeId = (badge: Badge) => {
        return badge.badgeId = `${badge.moduleId}_${badge.sequence}`;
    }

    const customValidation = (badge: Badge, errors: any) => {
        const existingBadge = badges.find(_ => Number(_.sequence) === Number(badge.sequence))
        if (existingBadge !== undefined && existingBadge.badgeId !== badge.badgeId) {
            return { ...errors, sequence: `The sequence ${badge.sequence} already exists` };
        }
        return errors
    }

    const handleSubmit = (badge: Badge) => {
        setSubmitting(true);
        calculateBadgeId(badge);

        if (newModel) {
            addBadge(badge).then(result => {
                toast.addToast(`Badge ${badgeDisplayName(result)} created`, "success");
                setBadge(result);
                setSubmitting(false);

                badges.push(result);

                navigate(EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT
                    .replace(PARAMETER_MODULE_ID, `${result.moduleId}`)
                    .replace(PARAMETER_BADGE_ID, `${result.badgeId}`), { state: location.state })
            }).catch(() => {
                toast.addToast(`Unable to create badge`, "error");
                setSubmitting(false);
            });
        } else {
            updateBadge(badge).then(result => {
                toast.addToast(`Badge ${badgeDisplayName(result)} updated`, "success");
                setBadge(result);
                setSubmitting(false);
            }).catch(() => {
                toast.addToast(`Unable to update ${badgeDisplayName(badge)} badge`, "error");
                setSubmitting(false);
            });
        }
    }

    const deleteWarnings = () => {
        return badge.hasStudentWork === true ? ['<b>Students has submitted work</b> for this badge,<br />deleting it will also <b>delete their work</b>.'] : undefined;
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            getModuleCohorts(badge.moduleId).then(cohorts => {
                if (cohorts === undefined || cohorts.length === 0) {
                    deleteBadge(badge.badgeId).then(() => {
                        toast.addToast(`Badge ${badgeDisplayName(badge)} deleted`, "success");
                        setDeleting(false);
                        navigate(EDUCATION_MANAGEMENT_MODULE_EDIT.replace(PARAMETER_MODULE_ID, `${badge.moduleId}`));
                    }).catch(() => {
                        toast.addToast(`Unable to delete ${badgeDisplayName(badge)} badge`, "error");
                        setDeleting(false);
                    });
                } else {
                    toast.addToast(`Badge ${badgeDisplayName(badge)} can not be deleted, the module is tought in ${cohorts.length} cohort(s). Remove the module from these cohorts first to delete its badges.`, "warning");
                    setDeleting(false);
                }
            });
        }
    }

    return (
        <React.Fragment>
            {newModel === false && <div className={styles.top}>
                <div className={styles.control} >
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => openAdd()}
                    >
                        Add next badge
                    </Button>
                </div>
            </div>}
            <BaseForm
                name={badgeDisplayName(badge)}
                model={badge}
                setModel={setBadge}
                fields={getFieldDefinitions()}
                submitEntity={handleSubmit}
                deleteEntity={!newModel ? handleDelete : undefined}
                submitting={submitting}
                deleting={deleting}
                customValidation={customValidation}
                deleteWarnings={deleteWarnings()}
                helpInformationText={[
                    "Checking <b>Allow resubmit</b> makes it possible for a student to submit an assignment for this badge again, IF it's been reviewed and the rating is only 1 star."
                ]}
            />
        </React.Fragment>

    );
}
