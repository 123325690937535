import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { BadgeAssignment } from "../model/badge-assignment";
import { ModuleAssignment } from "../model/module-assignment";

export function getStudentAssignments(): Promise<ModuleAssignment[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/list`, requestOptions)
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function submitStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(badgeAssignment)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions)
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function dismissStudentAssignment(badgeId: string): Promise<BadgeAssignment> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/dismiss/${badgeId}`, requestOptions)
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function updateStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(badgeAssignment)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions)
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function deleteStudentAssignment(workItemId: number): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/${workItemId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getEvaluatorAssignments(): Promise<ModuleAssignment[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator/list`, requestOptions)
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function updateEvaluatorAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(badgeAssignment)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator`, requestOptions)
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}
