import { AnswerType } from "../../enum/answer-type";
import { SurveyQuestionAnswer } from "./survey-question-answer";

export interface SurveyQuestion {
    id?: number | undefined,
    sequence: number,
    text: string,
    answerType: AnswerType,
    answerRequired: boolean,
    answers: SurveyQuestionAnswer[]
}

export interface QuestionValidationError {
    sequence: number,
    error: string
}

export const isAnswerOptionType = (answerType: AnswerType) => {
    return answerType === AnswerType.Multi_Select ||
        answerType === AnswerType.Select ||
        answerType === AnswerType.Radio;
}