import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Module, moduleDisplayName } from "../../model/module";
import { useToast } from "../../../../context/toast/toast-provider";
import { addModule, deleteModule, getModuleCohorts, updateModule } from "../../service/module-service";
import { EDUCATION_MANAGEMENT_MODULE_EDIT, EDUCATION_MANAGEMENT_MODULE_LIST, PARAMETER_MODULE_ID } from "../../../../infrastructure/route";
import { getBadges } from "../../service/badge-service";
import { moduleFieldDefinitions } from "./module-field-definition";
import { FieldDefinition } from "../../../../component/form/field-definition";
import BaseForm from "../../../../component/form/base-form";

export default function ModuleHandler({
    newModel,
    module,
    setModule
}: {
    newModel: boolean,
    module: Module,
    setModule: React.Dispatch<React.SetStateAction<Module>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();

    const getFieldDefinitions = (): FieldDefinition[] => {
        if (!newModel) {
            moduleFieldDefinitions.find(_ => _.id === "moduleId")!.disabled = true;
        } else {
            moduleFieldDefinitions.find(_ => _.id === "moduleId")!.disabled = false;
        }

        return moduleFieldDefinitions;
    }

    const handleSubmit = (module: Module) => {
        setSubmitting(true);

        if (newModel) {
            addModule(module).then(result => {
                toast.addToast(`Module ${moduleDisplayName(result)} created`, "success");
                setModule(result);
                setSubmitting(false);

                navigate(EDUCATION_MANAGEMENT_MODULE_EDIT.replace(PARAMETER_MODULE_ID, `${result.moduleId}`));
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The module ${moduleDisplayName(module)} already exists`, "error");
                } else {
                    toast.addToast(`Unable to create module`, "error");
                }
                setSubmitting(false);
            });
        } else {
            updateModule(module).then(result => {
                toast.addToast(`Module ${moduleDisplayName(result)} updated`, "success");
                setModule(result);
                setSubmitting(false);
            }).catch(() => {
                toast.addToast(`Unable to update ${moduleDisplayName(module)} module`, "error");
                setSubmitting(false);
            });
        }
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);

            getModuleCohorts(module.moduleId).then(cohorts => {
                if (cohorts === undefined || cohorts.length === 0) {
                    getBadges(module.moduleId).then(badges => {
                        if (badges === undefined || badges.length === 0) {
                            deleteModule(module.moduleId).then(() => {
                                toast.addToast(`Module ${moduleDisplayName(module)} deleted`, "success");
                                setDeleting(false);
                                navigate(EDUCATION_MANAGEMENT_MODULE_LIST);
                            }).catch(() => {
                                toast.addToast(`Unable to delete ${moduleDisplayName(module)} module`, "error");
                                setDeleting(false);
                            });
                        } else {
                            toast.addToast(`Module ${moduleDisplayName(module)} can not be deleted, it has ${badges.length} badge(s). Delete these first to delete the module.`, "warning");
                            setDeleting(false);
                        }
                    });
                } else {
                    toast.addToast(`Module ${moduleDisplayName(module)} can not be deleted, it is tought in ${cohorts.length} cohort(s). Remove it from these cohorts first to delete the module.`, "warning");
                    setDeleting(false);
                }
            });
        }
    }

    return (
        <BaseForm
            name={moduleDisplayName(module)}
            model={module}
            setModel={setModule}
            fields={getFieldDefinitions()}
            submitEntity={handleSubmit}
            deleteEntity={!newModel ? handleDelete : undefined}
            submitting={submitting}
            deleting={deleting}
        />
    );
}
