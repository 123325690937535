import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";
import { Evaluator } from "../model/evaluator";

export function getEvaluators(moduleId?: string | undefined): Promise<Evaluator[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/list?moduleId=${moduleId ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<Evaluator[]>(response)
        });
}

export function getEvaluator(evaluatorId: number): Promise<Evaluator> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/${evaluatorId}`, requestOptions)
        .then(response => {
            return handleResponse<Evaluator>(response)
        });
}

export function updateEvaluatorModules(evaluatorId: number, moduleIds: string[]): Promise<Evaluator> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(moduleIds)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/${evaluatorId}`, requestOptions)
        .then(response => {
            return handleResponse<Evaluator>(response)
        });
}