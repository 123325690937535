export enum AssignmentStatus {
    New = 1,
    Submitted = 2,
    Dismissed = 3,
    Evaluated = 4,
    Evaluated_Commented = 5,
    Evaluated_Rated = 6,
    Closed = 7,
    Unknown = 10
}

export const assignmentStatuses = Object.values(AssignmentStatus).filter((v) => !isNaN(Number(v))) as number[];