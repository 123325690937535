import { FieldDefinition } from "../../../../component/form/field-definition";

export const surveyFieldDefinitions: FieldDefinition[] = [
    {
        id: "title",
        name: "Title",
        type: "text",
        required: true,
        disabled: false,
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    },
    {
        id: "description",
        name: "Description",
        type: "text-area",
        required: true,
        disabled: false
    }
];