import { Survey } from "../../-examine/model/survey";
import { SurveySubmit } from "../../-examine/model/survey-submit";
import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";;

export function getSurvey(surveyId: number): Promise<Survey | undefined> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}`, requestOptions)
        .then(response => {
            if (response.status === 404) {
                return undefined;
            } else {
                return handleResponse<Survey>(response)
            }
        });
}

export function surveySubmit(surveyId: number, surveySubmit: SurveySubmit): Promise<void> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(surveySubmit)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}/submit`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}