import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../-account/model/user";
import { useToast } from "../../../../context/toast/toast-provider";
import { EDUCATION_PRESENTATION_EDIT, EDUCATION_PRESENTATION_LIST, PARAMETER_PRESENTER_DAY, PARAMETER_PRESENTER_ID } from "../../../../infrastructure/route";
import { Cohort } from "../../model/cohort";
import { Presentation } from "../../model/presentation";
import { addPresentation, deletePresentation, updatePresentation } from "../../service/presentation-service";
import { getStudents } from "../../service/student-service";
import { presentationFieldDefinitions } from "./presentation-field-definition";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { SelectItem } from "../../../../component/form/select-item";
import BaseForm from "../../../../component/form/base-form";
import BaseMultiSelect from "../../../../component/mulit-select/base-multi-select";
import { UserMultiSelectFilterModel, filterUsers } from "../../../../component/mulit-select/user/filter/user-multi-select-filter-model";
import UserMultiSelectFilter from "../../../../component/mulit-select/user/filter/user-multi-select-filter";

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default function PresentationHandler({
    newModel,
    presentation,
    setPresentation,
    cohorts
}: {
    newModel: boolean,
    presentation: Presentation,
    setPresentation: React.Dispatch<React.SetStateAction<Presentation>>,
    cohorts: Cohort[]
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [students, setStudents] = useState<User[]>([]);
    const [selectedStudents, setSelectedStudents] = useState<User[]>([]);
    const [studentFilter, setStudentFilter] = useState<UserMultiSelectFilterModel>({
        hideCohortId: true,
        hideCohortAssigned: true,
        hideGender: true,
        hideUsersSelected: true,
        hideUserSearch: !newModel,
        userSearch: ''
    });

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        getStudents(presentation.cohortId !== 0 ? presentation.cohortId : undefined).then(result => {
            setStudents(result ?? []);
            setSelectedStudents((result ?? []).filter(s => presentation.studentAttendance?.find(sp => sp.studentId === s.id) !== undefined))
        });
    }, [presentation.cohortId, presentation.studentAttendance]);

    const getFieldDefinitions = (): FieldDefinition[] => {
        if (!newModel) {
            presentationFieldDefinitions.find(_ => _.id === "day")!.disabled = true;
            presentationFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = true;

            if (presentation.cohortId === undefined) {
                presentationFieldDefinitions.find(_ => _.id === "cohortId")!.hidden = true;
            } else {
                presentationFieldDefinitions.find(_ => _.id === "cohortId")!.hidden = false;
            }
        } else {
            presentationFieldDefinitions.find(_ => _.id === "day")!.disabled = false;
            presentationFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = false;
            presentationFieldDefinitions.find(_ => _.id === "cohortId")!.hidden = false;
        }

        if (presentation.cohortId === undefined || !newModel) {
            presentationFieldDefinitions.find(_ => _.id === "presenterId")!.disabled = true;
        } else {
            presentationFieldDefinitions.find(_ => _.id === "presenterId")!.disabled = false;
        }

        const selectItemPresenters = students.map<SelectItem>(_ => { return { id: _.id, name: _.name } });
        presentationFieldDefinitions.find(_ => _.id === "presenterId")!.values = selectItemPresenters ?? [];

        if (presentationFieldDefinitions.find(_ => _.id === "cohortId") !== undefined) {
            const selectItemCohorts = [{ id: 0, name: "None" }].concat((cohorts !== undefined ? cohorts : []).map<SelectItem>(_ => { return { id: _.id, name: _.name } }))
            presentationFieldDefinitions.find(_ => _.id === "cohortId")!.values = selectItemCohorts;
        }

        return presentationFieldDefinitions;
    }

    const handleSubmit = () => {
        setSubmitting(true);

        let presentationRequest = { ...presentation };

        presentationRequest.day = dayjs.utc(dayjs(presentationRequest.day).format('YYYY-MM-DD')).format('YYYY-MM-DD');
        presentationRequest.studentAttendance = selectedStudents.map(_ => ({ studentId: _.id }));
        presentationRequest.cohortId = presentationRequest.cohortId !== undefined && presentationRequest.cohortId > 0 ? presentationRequest.cohortId : undefined;

        if (newModel) {
            addPresentation(presentationRequest).then(result => {
                toast.addToast(`Presentation by ${result.presenterName} on ${dayjs(result.day).format('YYYY-MM-DD')} created`, "success");
                setPresentation(result);
                setSubmitting(false);

                navigate(EDUCATION_PRESENTATION_EDIT
                    .replace(PARAMETER_PRESENTER_ID, `${result.presenterId}`)
                    .replace(PARAMETER_PRESENTER_DAY, `${dayjs(result.day).format('YYYY-MM-DD')}`));
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`Presentation on ${dayjs(presentationRequest.day).format('YYYY-MM-DD')} already exists`, "error");
                } else {
                    toast.addToast(`Unable to create presentation`, "error");
                }
                setSubmitting(false);
            });
        } else {
            updatePresentation(presentationRequest).then(result => {
                toast.addToast(`Presentation by ${result.presenterName} on ${dayjs(result.day).format('YYYY-MM-DD')} updated`, "success");
                setPresentation(result);
                setSubmitting(false);
            }).catch(() => {
                toast.addToast(`Unable to update presentation by ${presentationRequest.presenterName} on ${dayjs(presentationRequest.day).format('YYYY-MM-DD')}`, "error");
                setSubmitting(false);
            });
        }
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            deletePresentation(Number(presentation.presenterId), dayjs(presentation.day).format('YYYY-MM-DD')).then(() => {
                toast.addToast(`Presentation by ${presentation.presenterName} on ${dayjs(presentation.day).format('YYYY-MM-DD')} deleted`, "success");
                setDeleting(false);
                navigate(EDUCATION_PRESENTATION_LIST);
            }).catch(() => {
                toast.addToast(`Unable to delete presentation by ${presentation.presenterName} on ${dayjs(presentation.day).format('YYYY-MM-DD')}`, "error");
                setDeleting(false);
            });
        }
    }

    return (
        <BaseForm
            name={`${presentation.presenterName} on ${dayjs(presentation.day).format('YYYY-MM-DD')}`}
            model={presentation}
            setModel={setPresentation}
            fields={getFieldDefinitions()}

            submitEntity={handleSubmit}
            submitting={submitting}
            deleteEntity={!newModel ? handleDelete : undefined}
            deleting={deleting}
        >
            <UserMultiSelectFilter
                filter={studentFilter}
                setFilter={setStudentFilter}
                hidden={!newModel}
            />
            <BaseMultiSelect
                items={filterUsers(studentFilter, students, selectedStudents)}
                itemIdKey='id'
                selectedItems={selectedStudents}
                setSelectedItems={setSelectedStudents}

                selectedItemsShown={!newModel && selectedStudents.length > 0}
                selectedItemsTitle={`${selectedStudents.length} students(s) attended this presentation`}
                selectedItemsNoneText=''
                selectedItemRender={(user) => <p key={`selected-user-${user.id}`} >{user.name}</p>}

                availableItemsShown={newModel}
                availableItemsTitle='Attending student(s)'
                availableItemsNoneText='No students found to select'
                availableItemRender={(user) => <span key={`available-user-${user.id}`}>{user.name}</span>}

                disabled={submitting}
            />
        </BaseForm>
    );
}
