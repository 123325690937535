import { GridColDef } from "@mui/x-data-grid";
import { Role } from "../../../enum/role";
import { renderActive } from "../../../util/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'roles', headerName: 'Roles', flex: 2, valueFormatter: (params) => { return renderRoles(params.value) } },
    { field: 'active', headerName: 'Active', flex: 0.5, renderCell: (params) => { return renderActive(params.row.active) } }
];

const renderRoles = (roles: Role[]) => {
    return roles.map(_ => Role[_]).join(", ")
}