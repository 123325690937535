import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";
import { SurveySubmit } from "../model/survey-submit";

export function getSurveySubmits(from: string, to: string): Promise<SurveySubmit[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/list/${from}/${to}`, requestOptions)
        .then(response => {
            return handleResponse<SurveySubmit[]>(response)
        });
}

export function getSurveySubmit(surveySubmitId: number): Promise<SurveySubmit> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/${surveySubmitId}`, requestOptions)
        .then(response => {
            return handleResponse<SurveySubmit>(response)
        });
}