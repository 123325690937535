import { FieldDefinition } from "../../../../component/form/field-definition";
import { individualGenderOptions, userRoleOptions } from "../../../util/form-select-input-options";

export const userFieldDefinitions: FieldDefinition[] = [
    {
        id: "name",
        name: "Name",
        type: "text",
        required: true,
        disabled: false
    },
    {
        id: "phone",
        name: "Phone",
        type: "phone",
        required: false,
        disabled: false
    },
    {
        id: "email",
        name: "Email",
        type: "email",
        required: true,
        disabled: false
    },
    {
        id: "gender",
        name: "Gender",
        type: "select",
        required: false,
        disabled: false,
        values: individualGenderOptions()
    },
    {
        id: "roles",
        name: "Roles",
        type: "multi-select",
        required: false,
        disabled: false,
        values: userRoleOptions()
    },
    {
        id: "whatsApp",
        name: "WhatsApp",
        type: "text",
        required: false,
        disabled: false
    },
    {
        id: "idCafeteria",
        name: "IdCafeteria",
        type: "text",
        required: false,
        disabled: false
    },
    {
        id: "idCanteen",
        name: "IdCanteen",
        type: "text",
        required: false,
        disabled: false
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    }
];