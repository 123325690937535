import React, { useEffect, useState } from "react";
import { BaseList } from "../../../../../component/list/base-list";
import { DashboardManagerFilter } from "../../../model/dashboard-manger-filter";
import { CohortStudentModuleBadgeUnevaluated } from "../../../model/cohort-student-module-badge-unevaluated";
import { getCohortUnevaluatedBadges } from "../../../service/dashboard-manager-service";
import { columns } from "./cohort-student-module-badge-unevaluated-list-column-definition";
import BaseOperations from "../../../../../component/operations/base-operations";

const storageKey = "DASHBOARD_MANAGER_UNEVALUATED"

export default function CohortStudentModuleBadgeUnevaluatedList({
    filter,
    setFilter,
    expectedEvaluationDateOffsetHours
}: {
    filter: DashboardManagerFilter,
    setFilter: React.Dispatch<React.SetStateAction<DashboardManagerFilter>>,
    expectedEvaluationDateOffsetHours: number
}) {
    const [loading, setLoading] = useState(false);
    const [unevaluated, setUnevaluated] = useState<CohortStudentModuleBadgeUnevaluated[]>([]);

    useEffect(() => {
        if (filter.cohortId !== undefined) {
            setLoading(true);
            getCohortUnevaluatedBadges(
                filter.cohortId,
                Number(filter.moduleId) !== 0 ? filter.moduleId : undefined,
                filter.studentId !== 0 ? filter.studentId : undefined
            ).then(result => {
                setUnevaluated(result ?? []);
                setLoading(false);
            });
        } else {
            setUnevaluated([]);
        }
    }, [filter]);

    return (
        <React.Fragment>
            <BaseList
                storageKey={storageKey}
                style={{ marginBottom: "1.5rem" }}
                rows={unevaluated}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No unevaluated badges" }}
                getRowId={(row) => `${row?.cohortId}-${row?.badgeId}-${row?.studentId}`}
                disableRowSelectionOnClick={false}
                onRowClick={(e) => setFilter({ ...filter, moduleId: e.row?.moduleId, studentId: e.row?.studentId })}
                exportName={`${storageKey}_${filter.cohortId}`}
            />
            {unevaluated?.length > 0 && <BaseOperations
                helpInfoTexts={[
                    "You can click on a row in the list to select the <b>Module</b> and <b>Student</b>.",
                    `A badge is included if it <b>has not been evaluated</b> within the expected time frame of <b>${expectedEvaluationDateOffsetHours} hours</b> since the submission.`
                ]}
            />}
        </React.Fragment>
    );
}
