import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./cohort-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { Cohort } from "../../model/cohort";
import { getCohorts } from "../../service/cohort-service";
import { COHORT_TAB_STATE } from "../cohort-view";
import { EDUCATION_MANAGEMENT_COHORT_ADD, EDUCATION_MANAGEMENT_COHORT_EDIT, PARAMETER_COHORT_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function CohortList() {
    const [loading, setLoading] = useState(true);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getCohorts().then(result => {
            setCohorts(result ?? []);
            setLoading(false);
        });
    }, []);

    const openEdit = (cohortId: string) => {
        const cohort = cohorts.find(_ => _.id === Number(cohortId));
        cache.update({ key: PARAMETER_COHORT_ID, value: cohort?.name ?? '' });

        localStorage.removeItem(COHORT_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_COHORT_EDIT.replace(PARAMETER_COHORT_ID, `${cohortId}`));
    }

    const openAdd = () => {
        localStorage.removeItem(COHORT_TAB_STATE);
        navigate(EDUCATION_MANAGEMENT_COHORT_ADD);
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="COHORTS"
                rows={cohorts}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No cohorts" }}
                getRowId={(row) => row?.id}
                onRowClick={(e) => openEdit(e.row?.id)}
                onAddClick={() => openAdd()}
                addButtonText="Add cohort"
            />
        </div>
    );
}
