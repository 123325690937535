import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import styles from "./style/layout.module.css";
import Top from "../component/top/top";
import MenuDesktop from "../component/menu/menu-desktop";
import { useAuth } from "../context/auth/auth-provider";
import { isPublic } from "../infrastructure/route-access";

const theme = createTheme({
    palette: {
        primary: {
            main: "#6daa7d"
        },
        secondary: {
            main: "#c9e0cf"
        }
    },
});

document.documentElement.style.setProperty('--color-primary', theme.palette.primary.main);
document.documentElement.style.setProperty('--color-secondary', theme.palette.secondary.main);
document.documentElement.style.setProperty('--color-error', theme.palette.error.main);

export default function Layout() {

    const auth = useAuth();

    return (
        <ThemeProvider theme={theme}>
            <div className={styles.container} >
                {auth.user !== null && <MenuDesktop />}
                <div>
                    <Top />
                    <div className={auth.user !== null && isPublic() === false ? styles.outletWithMenu : styles.outletWithoutMenu}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </ThemeProvider>
    );
}
