import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EXAMINE_SURVEY_SUBMIT_VIEW, PARAMETER_SURVEY_SUBMIT_ID } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { SurveySubmit } from "../../model/survey-submit";
import { getSurveyRespondentSubmits } from "../../service/survey-respondent-service";
import { columns } from "./survey-respondent-submit-list-column-definition";
import { BaseList } from "../../../../component/list/base-list";

export const SURVEY_RESPONDENT_SUBMIT_LIST_FILTER_STATE: string = "SURVEY_RESPONDENT_SUBMIT_LIST_FILTER_STATE";
export const SURVEY_RESPONDENT_SUBMIT_LIST_SORTING_STATE: string = "SURVEY_RESPONDENT_SUBMIT_LIST_SORTING_STATE";

export default function SurveyRespondentSubmitList({
    surveyRespondentId
}: {
        surveyRespondentId: number
}) {
    const [loading, setLoading] = useState(true);
    const [surveySubmits, setSurveySubmits] = useState<SurveySubmit[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getSurveyRespondentSubmits(surveyRespondentId).then(result => {
            setSurveySubmits(result ?? []);
            setLoading(false);
        });
    }, [surveyRespondentId]);

    const openRead = (surveySubmitId: string) => {
        const surveySubmit = surveySubmits.find(_ => _.id === Number(surveySubmitId));
        cache.update({ key: PARAMETER_SURVEY_SUBMIT_ID, value: surveySubmit?.surveyTitle ?? '' });

        navigate(EXAMINE_SURVEY_SUBMIT_VIEW.replace(PARAMETER_SURVEY_SUBMIT_ID, `${surveySubmitId}`));
    }

    return (
        <BaseList
            storageKey="RESPONDENT_SUBMITS"
            rows={surveySubmits}
            columns={columns}
            loading={loading}
            localeText={{ noRowsLabel: "No submitted surveys" }}
            getRowId={(row) => `${row?.id}`}
            onRowClick={(e) => openRead(e.row?.id)}
        />
    );
}
