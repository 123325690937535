import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_SURVEY_RESPONDENT_ID } from "../../../infrastructure/route";
import { SurveyRespondent } from "../model/survey-respondent";
import { getSurveyRespondent } from "../service/survey-respondent-service";
import SurveyRespondentInfo from "./-survey-respondent-info/survey-respondent-info";
import SurveyRespondentSubmitList from "./-survey-respondent-submit-list/survey-respondent-submit-list";

export const SURVEY_RESPONDENT_TAB_STATE: string = "SURVEY_RESPONDENT_TAB_STATE";
export const SURVEY_RESPONDENT_TAB_STATE_INFO: string = "SURVEY_RESPONDENT_TAB_STATE_INFO";
export const SURVEY_RESPONDENT_TAB_STATE_SUBMITS: string = "SURVEY_RESPONDENT_TAB_STATE_SUBMITS";

export default function SurveyRespondentView() {
    const { surveyRespondentId } = useParams();
    let surveyRespondent = useRef<SurveyRespondent>();

    const [tab, setTab] = useState<string>(localStorage.getItem(SURVEY_RESPONDENT_TAB_STATE) ?? SURVEY_RESPONDENT_TAB_STATE_INFO);
    const [loading, setLoading] = useState<boolean>(true);

    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getSurveyRespondent(Number(surveyRespondentId)).then(result => {
            surveyRespondent.current = result;
            cache.update({ key: PARAMETER_SURVEY_RESPONDENT_ID, value: result.identity ?? '' });
            setLoading(false);
        })
    }, [cache, surveyRespondentId]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(SURVEY_RESPONDENT_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label="Info" value={SURVEY_RESPONDENT_TAB_STATE_INFO} />
                        <Tab label="Submits" value={SURVEY_RESPONDENT_TAB_STATE_SUBMITS} />
                    </TabList>
                </Box>
                <TabPanel value={SURVEY_RESPONDENT_TAB_STATE_INFO}>
                    <SurveyRespondentInfo surveyRespondent={surveyRespondent.current} />
                </TabPanel>
                <TabPanel value={SURVEY_RESPONDENT_TAB_STATE_SUBMITS}>
                    <SurveyRespondentSubmitList surveyRespondentId={surveyRespondent.current?.id ?? 0} />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
