import { User } from "../../-account/model/user";
import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";

export function getStudents(cohortId?: number | undefined): Promise<User[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/list?cohortId=${cohortId ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<Promise<User[]>>(response)
        });
}

export function updateStudentsCohort(cohortId: number, actorIds: Number[]): Promise<User[]> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(actorIds)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/student/${cohortId}`, requestOptions)
        .then(response => {
            return handleResponse<Promise<User[]>>(response)
        });
}