import { FieldDefinition } from "../../../../component/form/field-definition";

export const dashboardManagerFilterFieldDefinitions: FieldDefinition[] = [
    {
        id: "cohortId",
        name: "Cohort",
        type: "select",
        required: false,
        disabled: true,
        fullInput: true,
    },
    {
        id: "moduleId",
        name: "Module",
        type: "select",
        required: false,
        disabled: true
    },
    {
        id: "studentId",
        name: "Student",
        type: "select",
        required: false,
        disabled: true
    }
];