import { CourseLevel, courseLevels } from "../enum/course-level";
import { ModuleCategory, moduleCategories } from "../enum/module-category";
import { Module } from "./module";

export interface Evaluator {
    evaluatorId: number,
    evaluatorName: string,
    evaluatorEmail?: string | undefined,
    availability: number,
    metaRating: number,
    modules?: Module[] | undefined,
    courseLevels?: string | undefined,
    moduleCategories?: string | undefined
}

export const evaluatorDisplayName =(evaluator: Evaluator) => {
    if (evaluator.evaluatorEmail === undefined) {
        return evaluator.evaluatorName;
    } else {
        return `${evaluator.evaluatorName} (${evaluator.evaluatorEmail})`;
    }
}

export const getCourseLevels = (modules: Module[]) => {
    const evaluatorCourseLevels = courseLevels.filter(c => modules.filter(m => c === m.courseLevelId).length > 0);
    var summery = evaluatorCourseLevels.map(_ => `${CourseLevel[_].replace('_', ' ')} (${modules.filter(c => c.courseLevelId === _).length})`);
    return summery.join(', ');
}

export const getModuleCategories = (modules: Module[]) => {
    const evaluatorModuleCategories = moduleCategories.filter(c => modules.filter(m => c === m.categoryId).length > 0);
    var summery = evaluatorModuleCategories.map(_ => `${ModuleCategory[_].replace('_', ' ')} (${modules.filter(c => c.categoryId === _).length})`);
    return summery.join(', ');
}