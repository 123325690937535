import { useEffect, useState } from "react";
import commonStyles from "../../../style/common.module.css";
import { getAverageRating } from "../../service/statistic-service";
import { columns } from "./average-rating-badge-list-column-definition";
import { useParams } from "react-router-dom";
import { useCache } from "../../../../context/cache/cache-provider";
import { PARAMETER_COHORT_ID, PARAMETER_MODULE_ID } from "../../../../infrastructure/route";
import { getCohort } from "../../../-education/service/cohort-service";
import { Cohort } from "../../../-education/model/cohort";
import { AverageModule } from "../../model/average-module";
import { BaseList } from "../../../../component/list/base-list";

export default function AverageRatingBadgeList() {
    const { cohortId, moduleId } = useParams();
    const [loading, setLoading] = useState(true);
    const [cohort, setCohort] = useState<Cohort | undefined>();
    const [averageModule, setAverageModule] = useState<AverageModule | undefined>();

    useEffect(() => {
        setLoading(true);

        const getCohortCall = getCohort(Number(cohortId));
        const getAverageRatingCall = getAverageRating(Number(cohortId), moduleId);

        Promise.all([getCohortCall, getAverageRatingCall]).then(result => {
            setCohort(result[0]);
            setAverageModule(result[1].find(_ => _.moduleId === moduleId));
            setLoading(false);
        });
    }, [cohortId, moduleId]);

    const cache = useCache();

    useEffect(() => {
        cache.update(
            { key: PARAMETER_COHORT_ID, value: cohort?.name ?? '' },
            { key: PARAMETER_MODULE_ID, value: averageModule?.moduleTitle ?? '' }
        );
    }, [cache, cohort?.name, averageModule?.moduleTitle]);

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="AVERAGE_RATING_BADGE"
                rows={averageModule?.badges ?? []}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No average badge rating" }}
                getRowId={(row) => `${row?.badgeId}`}
            />
        </div>
    );
}
