import styles from "../../style/survey.module.css";
import commonStyles from "../../../style/common.module.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isAnswerOptionType, SurveyQuestion } from "../../model/survey-question";
import { surveyQuestionFieldDefinitions } from "./survey-question-field-definition";
import { surveyQuestionAnswerFieldDefinitions } from "./survey-question-answer-field-definition";
import { SurveyQuestionAnswer } from "../../model/survey-question-answer";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import MenuAction from "../../../../component/menu/menu-action";
import BaseForm from "../../../../component/form/base-form";

export default function SurveyHandlerQuestionContent({
    isExpanded,
    question,
    validationError,
    setQuestion,
    handleQuestionSelect,
    moveQuestion,
    removeQuestion
}: {
        isExpanded: boolean,
        question: SurveyQuestion,
        validationError: string | undefined,
        setQuestion(question: SurveyQuestion): void,
        handleQuestionSelect(sequence: number): void,
        moveQuestion(sequence: number, newSequence: number): void,
        removeQuestion(sequence: number): void,
}) {
    const updateQuestion = (question: SurveyQuestion) => {
        if (isAnswerOptionType(question.answerType)) {
            if (question.answers.length === 0) {
                question.answers = [{
                    sequence: 1,
                    text: ""
                }];
            }
        } else {
            question.answers = [];
        }

        setQuestion(question);
    }

    const setAnswer = (answer: SurveyQuestionAnswer) => {
        const updateAnswers = [...question.answers].filter(_ => _.sequence !== answer.sequence);
        updateAnswers.push(answer);
        question.answers = updateAnswers;

        updateQuestion(question);
    }

    const addAnswerOption = (answerSequence: number) => {
        const newAnswer = {
            sequence: answerSequence + 1,
            text: ""
        }

        question.answers.forEach(_ => {
            if (_.sequence >= answerSequence + 1) {
                _.sequence = _.sequence + 1;
            }
        });
        question.answers.push(newAnswer);

        updateQuestion(question);
    }

    const removeAnswerOption = (answerSequence: number) => {
        question.answers = question.answers.filter(_ => _.sequence !== answerSequence);
        updateQuestion(question);
    }

    return (
        <Accordion
            expanded={isExpanded}
            onChange={() => handleQuestionSelect(question.sequence)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: '99%', flexShrink: 0, }}>
                    {`Question ${question.sequence}: ${question.text.length > 50 ? question.text.substring(0, 50) + '...' : question.text}`}
                    {validationError !== undefined && <ErrorOutlineIcon color="error" sx={{ float: 'right' }} />}
                </Typography>
            </AccordionSummary>
            {isExpanded && <AccordionDetails>
                {validationError !== undefined && <div>
                    <p style={{ color: 'var(--color-error)' }} >{validationError}</p><br />
                </div>}
                <div style={{ display: 'flex' }} >
                    <BaseForm
                        name={`${question.text}`}
                        model={question}
                        setModel={updateQuestion}
                        fields={surveyQuestionFieldDefinitions}
                    />
                    <div className={styles.questionMenu} >
                        <MenuAction
                            handleMoveUp={() => moveQuestion(question.sequence, question.sequence - 1)}
                            handleMoveDown={() => moveQuestion(question.sequence, question.sequence + 1)}
                            handleDelete={() => removeQuestion(question.sequence)}
                            moveUpText={"Move question up"}
                            moveDownText={"Move question down"}
                            deleteText={"Remove question"}
                        />
                    </div>
                </div>
                {question.answers?.sort((a, b) => a.sequence - b.sequence).map(a => {
                    return (
                        <div key={a.sequence} style={{ display: 'flex' }} >
                            <BaseForm
                                name={`${question.text}`}
                                model={a}
                                setModel={(model) => setAnswer(model)}
                                fields={surveyQuestionAnswerFieldDefinitions}
                            />
                            <div className={styles.addRemoveAnswerOption} >
                                <AddCircleOutlineIcon
                                    className={commonStyles.iconClickable}
                                    onClick={() => addAnswerOption(a.sequence)} />
                                <RemoveCircleOutlineIcon
                                    className={commonStyles.iconClickable}
                                    onClick={() => removeAnswerOption(a.sequence)} />
                            </div>
                        </div>
                    )
                })}
            </AccordionDetails>}
        </Accordion>
    );
}