import React, { useCallback, useEffect, useState } from "react";
import { useToast } from "../../../../context/toast/toast-provider";
import BaseForm from "../../../../component/form/base-form";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { SelectItem } from "../../../../component/form/select-item";
import { updateEvaluatorAssignment } from "../../service/assignment-service";
import { evaluatorReviewSelectFieldDefinitions } from "./evaluator-review-select-field-definition";
import { evaluatorReviewFieldDefinitions } from "./evaluator-review-field-definition";
import { isValidHttpUrl } from "../../../util/helper/string-helper";
import { useSearchParams } from "react-router-dom";
import { ASSIGNMENT_QUERY_PARAMETER_MODULEID, ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW, ASSIGNMENT_QUERY_PARAMETER_WORKITEMID } from "../../util/assignment-constants";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    workItemId?: number | undefined
}

export default function EvaluatorReview({
    moduleAssignments,
    updateModuleAssignments
}: {
        moduleAssignments: ModuleAssignment[] | undefined,
        updateModuleAssignments(badgeAssignment: BadgeAssignment): void
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const workItemId = Number(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    const [filter, setFilter] = useState<AssignmentFilter>({
        moduleId: moduleId ?? undefined,
        workItemId: workItemId > 0 ? workItemId : undefined
    });

    const [review, setReview] = useState<BadgeAssignment>({ comment: "" });
    const [submitting, setSubmitting] = useState(false);

    const toast = useToast();

    const getModules = useCallback(() => {
        return moduleAssignments?.filter(_ => _.badges.filter(b => b.submissionDate !== undefined && b.evaluatedDate === undefined).length > 0) ?? [] as ModuleAssignment[];
    }, [moduleAssignments]);

    useEffect(() => {
        const workItem = getModules().flatMap(_ => _.badges).find(_ => _.workItemId === filter.workItemId);

        if (workItem !== undefined && workItem.moduleId === filter.moduleId) {
            setReview({ ...workItem, comment: "" });
        } else {
            setReview({ comment: "" });
        }
    }, [getModules, filter]);

    useEffect(() => {
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_EVALUATOR_REVIEW);
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_MODULEID, review.moduleId ?? "");
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID, review.workItemId?.toString() ?? "");
        setSearchParams(searchParams);
    }, [review, searchParams, setSearchParams]);

    const getReviewSelectFieldDefinitions = () => {
        const modules = getModules().map(_ => {
            let selectItem: SelectItem = {
                id: _.moduleId,
                name: _.moduleName
            }
            return selectItem;
        });

        if (filter.moduleId !== undefined && modules?.find(_ => _.id === filter.moduleId) === undefined) {
            setFilter({ ...filter, moduleId: undefined });
        }

        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "moduleId")!.values = modules;
        evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = true;

        if (filter.moduleId !== undefined) {
            const module = moduleAssignments?.find(_ => _.moduleId === filter.moduleId);

            const badges = module?.badges.filter(_ => _.submissionDate !== undefined && _.evaluatedDate === undefined).map(_ => {
                let selectItem: SelectItem = {
                    id: _.workItemId,
                    name: `${_.badgeName ?? _.workItemId} ${_.resubmits !== undefined && _.resubmits > 0 ? '(Resubmitted)' : ''}`
                }
                return selectItem;
            });

            if (filter.workItemId !== undefined && badges?.find(_ => _.id === filter.workItemId) === undefined) {
                setFilter({ ...filter, workItemId: undefined });
            }

            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.values = badges;
            evaluatorReviewSelectFieldDefinitions.find(_ => _.id === "workItemId")!.disabled = false;
        }

        return evaluatorReviewSelectFieldDefinitions;
    }

    const getReviewFieldDefinitions = (): FieldDefinition[] => {
        var isURL = isValidHttpUrl(review.workingBadgePath);
        evaluatorReviewFieldDefinitions.find(_ => _.id === "workingBadgePath")!.type = isURL ? 'link' : 'info';
        evaluatorReviewFieldDefinitions.find(_ => _.id === "rating")!.valueSuffixes = review.allowResubmit ? [{ value: 1, suffix: '(Can be resubmitted)'}] : undefined;
        return evaluatorReviewFieldDefinitions;
    }

    const customValidation = (badgeAssignment: BadgeAssignment, errors: any) => {
        const commentGiven = (badgeAssignment.comment !== undefined && badgeAssignment.comment !== '');
        const ratingGiven = (badgeAssignment.rating !== undefined && badgeAssignment.rating !== '' && Number(badgeAssignment.rating) !== 0);

        if (!commentGiven && !ratingGiven) {
            return {
                ...errors,
                rating: `Please provide either a comment or set a review rating`,
                comment: `Please provide either a comment or set a review rating`
            };
        }
        return errors
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        badgeAssignment.rating = badgeAssignment.rating?.toString();

        updateEvaluatorAssignment(badgeAssignment).then(result => {
            toast.addToast(`Great work! Session ${result.badgeName} submission has been reviewed`, "success");

            result.moduleId = badgeAssignment.moduleId;
            updateModuleAssignments(result);

            setReview({ comment: "" });
            setFilter({ ...filter, workItemId: undefined });
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to review session submission`, "error");
            setSubmitting(false);
        });
    }

    return (
        <React.Fragment>
            {getModules().length > 0 && <BaseForm
                model={filter}
                setModel={setFilter}
                fields={getReviewSelectFieldDefinitions()}
            />}
            {review.workItemId !== undefined && <BaseForm
                model={review}
                setModel={setReview}
                fields={getReviewFieldDefinitions()}
                customValidation={customValidation}
                submitEntity={handleSubmit}
                submitting={submitting}
                submitButtonText='Submit'
            />}
            {getModules().length === 0 && <div>
                <span>You have no sessions to <b>Review</b>. You can look at your previous session reviews under your <b>Journal</b>.</span>
            </div>}
        </React.Fragment>
    );
}
