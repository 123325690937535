import { Gender } from "../../enum/gender";
import { Role } from "../../enum/role";

export interface User {
    id: number,
    name: string,
    phone?: string | undefined,
    email?: string | undefined,
    gender?: Gender | undefined,
    roles: Role[],
    whatsApp?: string | undefined,
    IdCafeteria?: string | undefined,
    IdCanteen?: string | undefined,
    accessToken?: string | undefined,
    accessTokenExpire?: Date | undefined,
    active: boolean,
    hasPresentations?: boolean | undefined,
    hasEvaluationReviews?: boolean | undefined,
    hasEvaluationModulesAssigned?: boolean | undefined,
    isStudent?: boolean | undefined,
    cohortId?: number | undefined,
}

export const sortUserList = (users: User[]) => {
    return users
        .sort((user1, user2) => (user1?.name ?? '')
        .localeCompare((user2?.name ?? '')))
}

export const userDisplayName = (user: User) => {
    var extraInfo = ((user.phone ?? '') + (user.email ?? '') + (user.gender ?? '')).length > 0;
    return `${user.name}` + (extraInfo === true ? ` (${[(user.gender !== undefined ? Gender[user.gender] : ''), user.phone, user.email]
        .filter(_ => _ !== undefined && _ !== '')
        .join(', ')})` : '');
}

