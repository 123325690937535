export enum AnswerType {
    Text = 1,
    Text_Area = 2,
    Select = 3,
    Multi_Select = 4,
    Radio = 5,
    Date = 6,
    Boolean = 7
}

export const answerTypes = (Object.values(AnswerType).filter((v) => !isNaN(Number(v))) as number[]);