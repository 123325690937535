import React, { useEffect, useState } from "react";
import commonStyles from "../../style/common.module.css";
import { getStudentAssignments } from "../service/assignment-service";
import { ModuleAssignment } from "../model/module-assignment";
import { BadgeAssignment } from "../model/badge-assignment";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, LinearProgress, Tab } from "@mui/material";
import { useAuth } from "../../../context/auth/auth-provider";
import StudentSubmit from "./-student-submit/student-submit";
import StudentJournal from "./-student-journal/student-journal";
import { useSearchParams } from "react-router-dom";
import { ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT } from "../util/assignment-constants";

export default function StudentHome() {
    const [searchParams] = useSearchParams();
    const [tab, setTab] = useState<string>(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_TAB) ?? ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT);
    const [loading, setLoading] = useState(true);
    const [moduleAssignments, setModuleAssignments] = useState<ModuleAssignment[]>();

    const auth = useAuth();

    useEffect(() => {
        setLoading(true);
        getStudentAssignments().then(result => {
            result.forEach(_ => _.badges.forEach(b => b.moduleId = _.moduleId));
            setModuleAssignments(result);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }, []);

    const updateModuleAssignments = (badgeAssignment: BadgeAssignment) => {
        var updateModuleAssignments = [...moduleAssignments!];
        var module = updateModuleAssignments.find(_ => _.badges.filter(b => b.badgeId === badgeAssignment.badgeId).length > 0);
        var badge = module?.badges.find(_ => _.badgeId === badgeAssignment.badgeId);

        if (module !== undefined && badge !== undefined) {
            let bargeIndex = module?.badges.indexOf(badge);
            let moduleIndex = updateModuleAssignments.indexOf(module);

            module.badges[bargeIndex] = badgeAssignment;
            module.badges.sort((a, b) => {
                return (a.sequence ?? 0) - (b.sequence ?? 0)
            });

            updateModuleAssignments[moduleIndex] = module;
            
            setModuleAssignments(updateModuleAssignments);
        }
    }

    return (
        <React.Fragment>
            <div className={commonStyles.content}>
                <h3>Welcome {auth.user?.name}!</h3>
                <span>Happy to see you :) Are you ready to <b>Submit</b> some sessions?</span>
            </div>
            {loading === true && <LinearProgress />}
            {loading === false && <TabContext value={tab} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => setTab(newTab)}>
                        <Tab label="Submit" value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT} />
                        <Tab label="Journal" value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL} />
                    </TabList>
                </Box>
                <TabPanel value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT} >
                    <StudentSubmit
                        moduleAssignments={moduleAssignments}
                        updateModuleAssignments={updateModuleAssignments}
                        enableDismiss={false}
                    />
                </TabPanel>
                <TabPanel value={ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL} >
                    <StudentJournal
                        moduleAssignments={moduleAssignments}
                        updateModuleAssignments={updateModuleAssignments}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
