import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { SurveyRespondent } from "../../model/survey-respondent";

export const SURVEY_RESPONDENT_TAB_STATE: string = "SURVEY_RESPONDENT_TAB_STATE";
export const SURVEY_RESPONDENT_TAB_STATE_INFO: string = "SURVEY_RESPONDENT_TAB_STATE_INFO";
export const SURVEY_RESPONDENT_TAB_STATE_SUBMITS: string = "SURVEY_RESPONDENT_TAB_STATE_SUBMITS";

export default function SurveyRespondentInfo({
    surveyRespondent
}: {
    surveyRespondent?: SurveyRespondent | undefined
}) {
    return (
        <React.Fragment>
            <h2>{surveyRespondent?.identity}</h2>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: '100px' }}><b>Name</b></TableCell>
                            <TableCell>{surveyRespondent?.names.join(', ')}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: '100px' }}><b>E-mail address</b></TableCell>
                            <TableCell>{surveyRespondent?.emailAddresses.join(', ')}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: '100px' }}><b>Phone number</b></TableCell>
                            <TableCell>{surveyRespondent?.phoneNumbers.join(', ')}</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: '100px' }}><b>Submits</b></TableCell>
                            <TableCell>{surveyRespondent?.submits}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}
