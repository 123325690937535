import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { Survey } from "../model/survey";

export function getSurveys(): Promise<Survey[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/list`, requestOptions)
        .then(response => {
            return handleResponse<Survey[]>(response)
        });
}

export function getSurvey(surveyId: number): Promise<Survey> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/${surveyId}`, requestOptions)
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function addSurvey(survey: Survey): Promise<Survey> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(survey)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey`, requestOptions)
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function updateSurvey(survey: Survey): Promise<Survey> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(survey)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey`, requestOptions)
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function deleteSurvey(surveyId: number): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/${surveyId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}