import { FieldDefinition } from '../../../../component/form/field-definition';
import { SelectItem } from '../../../../component/form/select-item';
import { AnswerType, answerTypes } from '../../../enum/answer-type';

const answerTypeOptions = () => {
    return answerTypes.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: AnswerType[_].replace('_', ' ')
        }
        return selectItem;
    });
}

export const surveyQuestionFieldDefinitions: FieldDefinition[] = [
    {
        id: "text",
        name: "Text",
        type: "text",
        required: true,
        disabled: false,
    },
    {
        id: "answerType",
        name: "Answer type",
        type: "select",
        required: true,
        disabled: false,
        values: answerTypeOptions()
    },
    {
        id: "answerRequired",
        name: "Answer required",
        type: "boolean",
        required: true,
        disabled: false
    }
];