import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import commonStyles from "../../../style/common.module.css";
import styles from "../../../style/crud-control.module.css";
import { useCache } from "../../../../context/cache/cache-provider";
import { AverageModule } from "../../model/average-module";
import { getAverageRating } from "../../service/statistic-service";
import { columns } from "./average-rating-module-list-column-definition";
import { PARAMETER_COHORT_ID, PARAMETER_MODULE_ID, STATISTIC_AVERAGE_RATING_BADGE_LIST } from "../../../../infrastructure/route";
import { Cohort } from "../../../-education/model/cohort";
import { getCohorts } from "../../../-education/service/cohort-service";
import { BaseList } from "../../../../component/list/base-list";

export const AVERAGE_RATING_MODULE_LIST_FILTER_COHORT: string = "AVERAGE_RATING_MODULE_LIST_FILTER_COHORT";

export default function AverageRatingModuleList() {
    const [loading, setLoading] = useState(true);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);
    const [selectedCohort, setSelectedCohort] = useState<number | undefined>(undefined);
    const [averageModules, setAverageModule] = useState<AverageModule[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getCohorts().then(result => {
            setCohorts(result ?? []);

            const cohortId = cache.keys[AVERAGE_RATING_MODULE_LIST_FILTER_COHORT];
            setSelectedCohort(cohortId !== undefined ? Number(cohortId) : undefined);

            setLoading(false);
        });
    }, [cache.keys]);

    useEffect(() => {
        if (selectedCohort !== undefined) {
            getAverageRating(Number(selectedCohort)).then(result => {
                setAverageModule(result ?? []);
                cache.update({ key: AVERAGE_RATING_MODULE_LIST_FILTER_COHORT, value: `${selectedCohort}` })
            });
        }
    }, [selectedCohort, cache]);

    const openAverageRatingBadgeList = (moduleId: string) => {
        const averageModule = averageModules.find(_ => _.moduleId === moduleId);
        const cohort = cohorts.find(_ => _.id === selectedCohort);
        cache.update({ key: PARAMETER_COHORT_ID, value: cohort?.name ?? '' });
        cache.update({ key: PARAMETER_MODULE_ID, value: averageModule?.moduleTitle ?? '' });        

        navigate(STATISTIC_AVERAGE_RATING_BADGE_LIST
            .replace(PARAMETER_COHORT_ID, `${selectedCohort}`)
            .replace(PARAMETER_MODULE_ID, `${moduleId}`));
    }

    return (
        <div className={commonStyles.content} >       

            <div className={styles.filter}>
                <FormControl className={styles.filterInput} >
                    <InputLabel id='cohort-select'>Cohort</InputLabel>
                    <Select
                        labelId='cohort-select'
                        label='Cohort'
                        value={`${selectedCohort !== undefined ? selectedCohort : ''}`}
                        onChange={e => { setSelectedCohort(Number(e.target.value)) }}
                    >
                        {cohorts.map(cohort => {
                            return <MenuItem
                                key={cohort.id}
                                id={`${cohort.id}-menu-item`}
                                value={cohort.id}
                            >
                                {cohort.name}
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>
            </div>

            <BaseList
                storageKey="AVERAGE_RATING_MODULE"
                rows={averageModules}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No average module rating" }}
                getRowId={(row) => `${row?.moduleId}`}
                onRowClick={(e) => openAverageRatingBadgeList(e.row?.moduleId)}
            />
        </div>
    );
}
