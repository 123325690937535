import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./attendance-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import dayjs, { Dayjs } from "dayjs";
import { EDUCATION_ATTENDANCE_UPDATE, PARAMETER_ATTENDANCE_DAY } from "../../../../infrastructure/route";
import { useCache } from "../../../../context/cache/cache-provider";
import { getAttendances } from "../../service/attendance-service";
import { AttendanceOverview } from "../../model/attendance-overview";
import { BaseList } from "../../../../component/list/base-list";

export const ATTENDANCE_CACHE_COHORT_ID = "ATTENDANCE_CACHE_COHORT_ID";

export default function AttendanceList() {
    const [loading, setLoading] = useState(true);
    const [attendances, setAttendances] = useState<AttendanceOverview[]>([]);
    const [from, setFrom] = useState<Dayjs | null>(null);
    const [to, setTo] = useState<Dayjs | null>(null);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        if (from !== null && to !== null) {
            setLoading(true);
            getAttendances(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD')).then(result => {
                setAttendances(result ?? []);
                setLoading(false);
            });
        }
    }, [from, to]);

    const openUpdate = (day: string, cohortId?: number | undefined) => {
        const attendance = attendances.find(_ => dayjs(_.day).format('YYYY-MM-DD') === day);
        cache.update(
            { key: PARAMETER_ATTENDANCE_DAY, value: dayjs(attendance?.day).format('YYYY-MM-DD') }
        );

        if (cohortId !== undefined) {
            cache.update(
                { key: ATTENDANCE_CACHE_COHORT_ID, value: cohortId.toString() }
            );
        }

        navigate(EDUCATION_ATTENDANCE_UPDATE.replace(PARAMETER_ATTENDANCE_DAY, `${day}`));
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="ATTENDANCE"
                rows={attendances}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No attendance" }}
                getRowId={(row) => `${row?.day}_${row?.cohortId}`}
                onRowClick={(e) => openUpdate(dayjs(e.row?.day).format('YYYY-MM-DD'), e.row?.cohortId)}
                onAddClick={() => openUpdate(dayjs(new Date()).format('YYYY-MM-DD'), undefined)}
                addButtonText="Update attedance"
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
            />
        </div>
    );
}
