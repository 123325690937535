export const PARAMETER_MODULE_ID = ":moduleId";
export const PARAMETER_BADGE_ID = ":badgeId";
export const PARAMETER_COHORT_ID = ":cohortId";
export const PARAMETER_EVALUATOR_ID = ":evaluatorId";
export const PARAMETER_PRESENTER_ID = ":presenterId";
export const PARAMETER_PRESENTER_DAY = ":day";
export const PARAMETER_ATTENDANCE_DAY = ":day";
export const PARAMETER_USER_ID = ":userId";
export const PARAMETER_SURVEY_ID = ":surveyId";
export const PARAMETER_SURVEY_SUBMIT_ID = ":surveySubmitId";
export const PARAMETER_SURVEY_RESPONDENT_ID = ":surveyRespondentId";

export const HOME: string = "/";
export const LOGIN: string = "/login";
export const NO_ACCESS: string = "/no-access";

export const DASHBOARD_MANAGER = "/dashboard/manager";

export const WORK_MY_ASSIGNMENTS: string = "/work/my/assignments";
export const WORK_MY_REVIEWS: string = "/work/my/reviews";

export const PUBLIC_SURVEY: string = `/public/survey/${PARAMETER_SURVEY_ID}`;

export const EDUCATION_MANAGEMENT_MODULE_LIST: string = `/education/module`;
export const EDUCATION_MANAGEMENT_MODULE_ADD: string = `/education/module/add`;
export const EDUCATION_MANAGEMENT_MODULE_EDIT: string = `/education/module/${PARAMETER_MODULE_ID}`;
export const EDUCATION_MANAGEMENT_MODULE_BADGE_ADD: string = `/education/module/${PARAMETER_MODULE_ID}/badge/add`;
export const EDUCATION_MANAGEMENT_MODULE_BADGE_EDIT: string = `/education/module/${PARAMETER_MODULE_ID}/badge/${PARAMETER_BADGE_ID}`;
export const EDUCATION_MANAGEMENT_COHORT_LIST: string = `/education/cohort`;
export const EDUCATION_MANAGEMENT_COHORT_ADD: string = `/education/cohort/add`;
export const EDUCATION_MANAGEMENT_COHORT_EDIT: string = `/education/cohort/${PARAMETER_COHORT_ID}`;
export const EDUCATION_MANAGEMENT_EVALUATOR_LIST: string = `/education/evaluator`;
export const EDUCATION_MANAGEMENT_EVALUATOR_ADD: string = `/education/evaluator/add`;
export const EDUCATION_MANAGEMENT_EVALUATOR_MODULE_ASSIGN: string = `/education/evaluator/${PARAMETER_EVALUATOR_ID}`;
export const EDUCATION_PRESENTATION_LIST: string = `/education/presentation`;
export const EDUCATION_PRESENTATION_ADD: string = `/education/presentation/add`;
export const EDUCATION_PRESENTATION_EDIT: string = `/education/presentation/${PARAMETER_PRESENTER_ID}/${PARAMETER_PRESENTER_DAY}`;
export const EDUCATION_ATTENDANCE_LIST: string = `/education/attendance`;
export const EDUCATION_ATTENDANCE_UPDATE: string = `/education/attendance/update/${PARAMETER_ATTENDANCE_DAY}`;

export const EXAMINE_SURVEY_HANDLE_LIST: string = `/examine/survey-handle`;
export const EXAMINE_SURVEY_HANDLE_ADD: string = `/examine/survey-handle/add`;
export const EXAMINE_SURVEY_HANDLE_EDIT: string = `/examine/survey-handle/${PARAMETER_SURVEY_ID}`;
export const EXAMINE_SURVEY_SUBMIT_LIST: string = `/examine/survey-submit`;
export const EXAMINE_SURVEY_SUBMIT_VIEW: string = `/examine/survey-submit/${PARAMETER_SURVEY_SUBMIT_ID}`;
export const EXAMINE_SURVEY_RESPONDENT_LIST: string = `/examine/survey-respondent`;
export const EXAMINE_SURVEY_RESPONDENT_VIEW: string = `/examine/survey-respondent/${PARAMETER_SURVEY_RESPONDENT_ID}`;

export const STATISTIC_AVERAGE_RATING_MODULE_LIST: string = `/statistic/average-rating`;
export const STATISTIC_AVERAGE_RATING_BADGE_LIST: string = `/statistic/average-rating/${PARAMETER_COHORT_ID}/${PARAMETER_MODULE_ID}`;

export const ACCOUNT_MANAGEMENT_USER_LIST: string = `/account/user`;
export const ACCOUNT_MANAGEMENT_USER_ADD: string = `/account/user/add`;
export const ACCOUNT_MANAGEMENT_USER_EDIT: string = `/account/user/${PARAMETER_USER_ID}`;
