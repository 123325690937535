import { useState } from "react";
import { useToast } from "../../../../context/toast/toast-provider";
import { Evaluator } from "../../model/evaluator";
import { updateEvaluatorModules } from "../../service/evaluator-service";
import { Module } from "../../model/module";
import { ModuleMultiSelect } from "../../../../component/mulit-select/module/module-multi-select";
import { ModuleMultiSelectFilterModel } from "../../../../component/mulit-select/module/filter/module-multi-select-filter-model";
import { YesNo } from "../../../../component/mulit-select/enum/yes-no";

export default function EvaluatorModuleAssign({ evaluator }: { evaluator: Evaluator }) {

    const getEvaluatorCourseLevel = () => {
        const evaluatorCourseLevels = Array.from(new Set(evaluator?.modules?.map(_ => _.courseLevelId)));
        return evaluatorCourseLevels.length === 1 ? evaluatorCourseLevels.pop() : undefined;
    }

    const getEvaluatorModuleCategory = () => {
        const evaluatorModuleCategories = Array.from(new Set(evaluator?.modules?.map(_ => _.categoryId)));
        return evaluatorModuleCategories.length === 1 ? evaluatorModuleCategories.pop() : undefined;
    }

    const [submitting, setSubmitting] = useState(false);
    const [selectedModules, setSelectedModules] = useState<Module[]>(evaluator.modules ?? []);
    const [moduleFilter, setModuleFilter] = useState<ModuleMultiSelectFilterModel>({
        courseLevel: getEvaluatorCourseLevel(),
        moduleCategory: getEvaluatorModuleCategory(),
        moduleSearch: '',
        modulesSelected: YesNo.All
    });

    const toast = useToast();

    const handleSubmit = () => {
        setSubmitting(true);

        updateEvaluatorModules(evaluator.evaluatorId, selectedModules.map(_ => _.moduleId)).then(result => {
            if (result.modules?.length !== 0) {
                toast.addToast(`Evaluator ${result?.evaluatorName} updated to cover ${result.modules?.length} module(s)`, "success");
            } else {
                toast.addToast(`Evaluator ${result?.evaluatorName} updated and all module(s) removed`, "success");
            }

            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update module(s) for evaluator ${evaluator?.evaluatorName}`, "error");
            setSubmitting(false);
        });
    }

    return (
        <ModuleMultiSelect
            selectedItems={selectedModules}
            setSelectedItems={setSelectedModules}
            filter={moduleFilter}
            setFilter={setModuleFilter}
            handleSubmit={handleSubmit}
            submitting={submitting}
        />
    );
}
