import { Role } from "../../enum/role";
import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { User } from "../model/user";

export function getSelf(accessToken: string): Promise<User> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/self`, requestOptions)
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function getUsers(roles?: Role[]): Promise<User[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    var rolesQuery = roles?.map(_ => `roles=${_}`).join('&');
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/list?${rolesQuery ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<User[]>(response)
        });
}

export function getUser(userId: number): Promise<User> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/${userId}`, requestOptions)
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function addUser(user: User): Promise<User> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor`, requestOptions)
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function updateUser(user: User): Promise<User> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor`, requestOptions)
        .then(response => {
            return handleResponse<User>(response)
        });
}

export function deleteUser(userId: number): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/actor/${userId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}

