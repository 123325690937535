import React, { useEffect, useState } from "react";
import commonStyles from "../../../style/common.module.css";
import { Alert, Stack } from "@mui/material";
import { Cohort } from "../../model/cohort";
import { CohortStatus } from "../../model/cohort-status";
import { getCohortStatus } from "../../service/cohort-service";
import ViewLoader from "../../../../component/misc/view-loader";

export default function CohortStatusSummary({ cohort }: { cohort: Cohort }) {
    const [loading, setLoading] = useState(true);
    const [cohortStatus, setCohortStatus] = useState<CohortStatus | undefined>();

    useEffect(() => {
        setLoading(true);

        getCohortStatus(Number(cohort.id)).then((result) => {
            setCohortStatus(result);
            setLoading(false);
        });
    }, [cohort.id]);

    const anyWarnings = () => {
        return cohortStatus?.cohortIsActive === false ||
            cohortStatus?.students === 0 ||
            cohortStatus?.modules === 0 ||
            Object.getOwnPropertyNames(cohortStatus?.badgesForModules).filter(_ => cohortStatus?.badgesForModules[_] === 0).length > 0 ||
            Object.getOwnPropertyNames(cohortStatus?.evaluatorsForModules).filter(_ => cohortStatus?.evaluatorsForModules[_] === 0).length > 0
    }

    const anySuccess = () => {
        return cohortStatus !== undefined && (cohortStatus?.cohortIsActive === true ||
            cohortStatus.students > 0 ||
            cohortStatus.modules > 0 ||
            Object.getOwnPropertyNames(cohortStatus?.badgesForModules).filter(_ => cohortStatus?.badgesForModules[_] > 0).length > 0 ||
            Object.getOwnPropertyNames(cohortStatus?.evaluatorsForModules).filter(_ => cohortStatus?.evaluatorsForModules[_] > 0).length > 0)
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && cohortStatus !== undefined && anyWarnings() === true && <Stack sx={{ width: '100%' }} spacing={1}>
                <h4>Warning</h4>
                {cohortStatus?.cohortIsActive === false && <Alert className={commonStyles.alert} severity="warning">
                    The cohort is <b>not active</b>
                </Alert>}
                {cohortStatus?.students === 0 && <Alert className={commonStyles.alert} severity="warning">
                    The cohort has <b>no students</b> assigned to it
                </Alert>}
                {cohortStatus?.modules === 0 && <Alert className={commonStyles.alert} severity="warning">
                    The cohort has <b>no modules</b> assigned to it
                </Alert>}
                {Object.getOwnPropertyNames(cohortStatus?.badgesForModules).filter(_ => cohortStatus?.badgesForModules[_] === 0).map(_ => {
                    return <Alert key={`${_}-no-badges`} className={commonStyles.alert} severity="warning">
                        Module <b>{_}</b> has <b>no badges</b>
                    </Alert>
                })}
                {Object.getOwnPropertyNames(cohortStatus?.evaluatorsForModules).filter(_ => cohortStatus?.evaluatorsForModules[_] === 0).map(_ => {
                    return <Alert key={`${_}-no-evaluator`} className={commonStyles.alert} severity="warning">
                        Module <b>{_}</b> has <b>no evaluators</b>
                    </Alert>
                })}
                <br />
            </Stack>}
            {loading === false && cohortStatus !== undefined && anySuccess() === true && <Stack sx={{ width: '100%' }} spacing={1}>
                <h4>Success</h4>
                {cohortStatus?.cohortIsActive === true && <Alert className={commonStyles.alert} severity="success">
                    The cohort is <b>active</b>
                </Alert>}
                {cohortStatus?.students > 0 && <Alert className={commonStyles.alert} severity="success">
                    The cohort has <b>{cohortStatus?.students} students</b> assigned to it
                </Alert>}
                {cohortStatus?.modules > 0 && <Alert className={commonStyles.alert} severity="success">
                    The cohort has <b>{cohortStatus?.modules} modules</b> assigned to it
                </Alert>}
                {Object.getOwnPropertyNames(cohortStatus?.badgesForModules).filter(_ => cohortStatus?.badgesForModules[_] > 0).map(_ => {
                    return <Alert key={`${_}-no-badges`} className={commonStyles.alert} severity="success">
                        Module <b>{_}</b> has <b>{cohortStatus?.badgesForModules[_]} badges</b>
                    </Alert>
                })}
                {Object.getOwnPropertyNames(cohortStatus?.evaluatorsForModules).filter(_ => cohortStatus?.evaluatorsForModules[_] > 0).map(_ => {
                    return <Alert key={`${_}-no-evaluator`} className={commonStyles.alert} severity="success">
                        Module <b>{_}</b> has <b>{cohortStatus?.evaluatorsForModules[_]} evaluators</b>
                    </Alert>
                })}
            </Stack>}
        </React.Fragment>
    );
}
