import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import commonStyles from "../../../style/common.module.css";
import { Cohort, cohortDisplayName } from "../../model/cohort";
import { Module } from "../../model/module";
import { getModuleCohorts } from "../../service/module-service";
import { EDUCATION_MANAGEMENT_COHORT_EDIT, PARAMETER_COHORT_ID } from "../../../../infrastructure/route";
import { useAuth } from "../../../../context/auth/auth-provider";
import { ROUTE_SETUP } from "../../../../infrastructure/route-setup";
import ViewLoader from "../../../../component/misc/view-loader";

export default function ModuleCohortList({ module }: { module: Module }) {
    const [loading, setLoading] = useState(true);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);

    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        setLoading(true);
        getModuleCohorts(module.moduleId).then(result => {
            setCohorts(result ?? []);
            setLoading(false);
        });
    }, [module]);

    const canOpenCohort = () => {
        const route = ROUTE_SETUP.flatMap(_ => _.routes).find(_ => _.path === EDUCATION_MANAGEMENT_COHORT_EDIT);
        if (route !== undefined) {
            return auth.hasAnyRoles(route.roles);
        }
    }

    const openCohort = (cohortId: number) => {
        if (canOpenCohort()) {
            navigate(EDUCATION_MANAGEMENT_COHORT_EDIT.replace(PARAMETER_COHORT_ID, cohortId.toString()));
        }
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <div>
                <h4>Cohort(s) teaching module</h4>
                {cohorts.length === 0 && <p><i>No cohorts</i></p>}
                {cohorts.length > 0 && cohorts.map(cohort => {
                    return <p
                        key={cohort.id}
                        className={canOpenCohort() ? commonStyles.clickable : ''}
                        onClick={() => openCohort(cohort.id!)} >
                        {cohortDisplayName(cohort)}
                    </p>
                })}
            </div>}
        </React.Fragment>
    );
}
