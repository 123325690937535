import { GridColDef } from "@mui/x-data-grid";
import { renderActive, renderChecked } from "../../../../util/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'badgeId', headerName: 'Id', flex: 0.5 },
    { field: 'sequence', headerName: 'Sequence', flex: 0.5 },
    { field: 'title', headerName: 'Title', flex: 1.5 },
    { field: 'estimatedTime', headerName: 'Estimated time (h)', flex: 0.5 },
    { field: 'allowResubmit', headerName: 'Allow resubmit', flex: 0.5, renderCell: (params) => { return renderChecked(params.row.allowResubmit) } },
    { field: 'active', headerName: 'Active', flex: 0.5, renderCell: (params) => { return renderActive(params.row.active) } }
];