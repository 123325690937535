import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./survey-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { useCache } from "../../../../context/cache/cache-provider";
import { Survey } from "../../model/survey";
import { getSurveys } from "../../service/survey-service";
import { EXAMINE_SURVEY_HANDLE_ADD, EXAMINE_SURVEY_HANDLE_EDIT, PARAMETER_SURVEY_ID } from "../../../../infrastructure/route";
import { BaseList } from "../../../../component/list/base-list";

export default function SurveyList() {
    const [loading, setLoading] = useState(true);
    const [surveys, setSurveys] = useState<Survey[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getSurveys().then(result => {
            setSurveys(result ?? []);
            setLoading(false);
        });
    }, []);

    const openEdit = (surveyId: string) => {
        const survey = surveys.find(_ => _.id === Number(surveyId));
        cache.update({ key: PARAMETER_SURVEY_ID, value: survey?.title ?? '' });

        navigate(EXAMINE_SURVEY_HANDLE_EDIT.replace(PARAMETER_SURVEY_ID, `${surveyId}`));
    }

    const openAdd = () => {
        navigate(EXAMINE_SURVEY_HANDLE_ADD);
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="SURVEY"
                rows={surveys}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No surveys" }}
                getRowId={(row) => row?.id}
                onRowClick={(e) => openEdit(e.row?.id)}
                onAddClick={() => openAdd()}
                addButtonText="Add survey"
            />
        </div>
    );
}
