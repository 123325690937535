import { User } from "../../../../section/-account/model/user";
import { Gender } from "../../../../section/enum/gender";
import { YesNo } from "../../enum/yes-no";

export interface UserMultiSelectFilterModel {
    cohortId?: number | undefined,
    lockCohortId?: boolean | undefined,
    hideCohortId?: boolean | undefined,

    gender?: Gender | undefined,
    lockGender?: boolean | undefined,
    hideGender?: boolean | undefined,

    cohortAssigned?: YesNo | undefined,
    lockCohortAssigned?: boolean | undefined,
    hideCohortAssigned?: boolean | undefined,

    userSearch?: string | undefined,
    lockUserSearch?: boolean | undefined,
    hideUserSearch?: boolean | undefined,

    usersSelected?: YesNo | undefined,
    lockUsersSelected?: boolean | undefined,
    hideUsersSelected?: boolean | undefined,

    excludeUsers?: number[] | undefined
}

export const filterUsers = (filter: UserMultiSelectFilterModel, users: User[], selectedUsers: User[]) => {
    return users
        .filter(user =>
            (filter.excludeUsers === undefined ||
                filter.excludeUsers.filter(excludeUserId => excludeUserId === user.id).length === 0) &&
            (filter.userSearch === undefined ||
                user.name.toLowerCase().includes(filter.userSearch.toLowerCase()) ||
                user.email?.toLowerCase().includes(filter.userSearch.toLowerCase()) ||
                user.phone?.toLowerCase().includes(filter.userSearch.toLowerCase())) &&
            (filter.gender === undefined ||
                user.gender === undefined ||
                filter.gender === Gender.All ||
                filter.gender === user.gender) &&
            (filter.cohortAssigned === undefined || filter.cohortId === undefined ||
                filter.cohortId === user.cohortId ||
                filter.cohortAssigned === YesNo.All ||
                (filter.cohortAssigned === YesNo.No && user.cohortId === undefined) ||
                (filter.cohortAssigned === YesNo.Yes && user.cohortId !== undefined)) &&
            (filter.usersSelected === undefined ||
                filter.usersSelected === YesNo.All ||
                (filter.usersSelected === YesNo.No && selectedUsers.find(_ => _.id === user.id) === undefined) ||
                (filter.usersSelected === YesNo.Yes && selectedUsers.find(_ => _.id === user.id) !== undefined)));
}