import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";
import { AverageModule } from "../model/average-module";

export function getAverageRating(cohortId: number, moduleId?: string | undefined): Promise<AverageModule[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/statistic/average/rating/${cohortId}?moduleId=${moduleId ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<AverageModule[]>(response)
        });
}