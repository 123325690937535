import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { Presentation } from "../model/presentation";

export function getPresentations(from: string, to: string): Promise<Presentation[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/list/${from}/${to}`, requestOptions)
        .then(response => {
            return handleResponse<Presentation[]>(response)
        });
}

export function getPresentation(presenterId: number, day: string): Promise<Presentation> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/${presenterId}/${day}`, requestOptions)
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function addPresentation(presentation: Presentation): Promise<Presentation> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(presentation)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation`, requestOptions)
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function updatePresentation(presentation: Presentation): Promise<Presentation> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(presentation)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation`, requestOptions)
        .then(response => {
            return handleResponse<Presentation>(response)
        });
}

export function deletePresentation(presenterId: number, day: string): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/presentation/${presenterId}/${day}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}