import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { Cohort } from "../model/cohort";
import { Module } from "../model/module";
import { ModulePrerequisite } from "../model/module-prerequisite";

export function getModules(courseLevelId?: number | undefined, active?: boolean | undefined): Promise<Module[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/list?courseLevelId=${courseLevelId ?? ''}&active=${active ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<Module[]>(response)
        });
}

export function getModule(moduleId: string): Promise<Module> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}`, requestOptions)
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function addModule(module: Module): Promise<Module> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(module)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module`, requestOptions)
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function updateModule(module: Module): Promise<Module> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(module)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module`, requestOptions)
        .then(response => {
            return handleResponse<Module>(response)
        });
}

export function deleteModule(moduleId: string): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getModuleCohorts(moduleId: string): Promise<Cohort[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/cohort`, requestOptions)
        .then(response => {
            return handleResponse<Cohort[]>(response)
        });
}


export function getModulePrerequisites(moduleId: string): Promise<ModulePrerequisite[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/prerequisite`, requestOptions)
        .then(response => {
            return handleResponse<ModulePrerequisite[]>(response)
        });
}

export function updateModulePrerequisites(moduleId: string, modulePrerequisite: ModulePrerequisite[]): Promise<ModulePrerequisite[]> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(modulePrerequisite)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/module/${moduleId}/prerequisite`, requestOptions)
        .then(response => {
            return handleResponse<Promise<ModulePrerequisite[]>>(response)
        });
}