import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { Cohort } from "../model/cohort";
import { CohortStatus } from "../model/cohort-status";
import { Module } from "../model/module";

export function getCohorts(): Promise<Cohort[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/list`, requestOptions)
        .then(response => {
            return handleResponse<Cohort[]>(response)
        });
}

export function getCohort(cohortId: number): Promise<Cohort> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}`, requestOptions)
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function getCohortStatus(cohortId: number): Promise<CohortStatus> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/status`, requestOptions)
        .then(response => {
            return handleResponse<CohortStatus>(response)
        });
}

export function addCohort(cohort: Cohort): Promise<Cohort> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cohort)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort`, requestOptions)
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function updateCohort(cohort: Cohort): Promise<Cohort> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cohort)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort`, requestOptions)
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function deleteCohort(cohortId: number): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getCohortModules(cohortId: number): Promise<Module[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/module/list`, requestOptions)
        .then(response => {
            return handleResponse<Module[]>(response)
        });
}

export function updateCohortModules(cohortId: number, moduleIds: string[]): Promise<Module[]> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(moduleIds)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/module`, requestOptions)
        .then(response => {
            return handleResponse<Promise<Module[]>>(response)
        });
}