import React, { useCallback, useEffect, useState } from "react";
import { useToast } from "../../../../context/toast/toast-provider";
import commonStyles from "../../../style/common.module.css";
import BaseForm from "../../../../component/form/base-form";
import { BadgeAssignment } from "../../model/badge-assignment";
import { ModuleAssignment } from "../../model/module-assignment";
import { SelectItem } from "../../../../component/form/select-item";
import { dismissStudentAssignment, submitStudentAssignment } from "../../service/assignment-service";
import { studentSubmitSelectFieldDefinitions } from "./student-submit-select-field-definition";
import { studentSubmitFieldDefinitions } from "./student-submit-field-definition";
import { Alert } from "@mui/material";
import { ASSIGNMENT_QUERY_PARAMETER_BADGEID, ASSIGNMENT_QUERY_PARAMETER_MODULEID, ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT, ASSIGNMENT_QUERY_PARAMETER_WORKITEMID } from "../../util/assignment-constants";
import { useSearchParams } from "react-router-dom";

export interface AssignmentFilter {
    moduleId?: string | undefined,
    badgeId?: string | undefined
}

export default function StudentSubmit({
    moduleAssignments,
    updateModuleAssignments,
    enableDismiss
}: {
    moduleAssignments: ModuleAssignment[] | undefined,
    updateModuleAssignments(badgeAssignment: BadgeAssignment): void,
    enableDismiss: boolean
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const badgeId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_BADGEID);
    const [filter, setFilter] = useState<AssignmentFilter>({
        moduleId: moduleId ?? undefined,
        badgeId: badgeId ?? undefined
    });

    const [submitting, setSubmitting] = useState(false);
    const [dismissing, setDismissing] = useState(false);
    const [submit, setSubmit] = useState<BadgeAssignment>({ workingBadgePath: "" });

    const toast = useToast();

    const getModules = useCallback(() => {
        return moduleAssignments?.filter(_ => _.badges.filter(b => b.submissionDate === undefined || b.canResubmit === true).length > 0) ?? [] as ModuleAssignment[];
    }, [moduleAssignments]);

    useEffect(() => {
        const badge = getModules().flatMap(_ => _.badges).find(_ => _.badgeId === filter.badgeId);

        if (badge !== undefined && badge.moduleId === filter.moduleId) {
            setSubmit({ ...badge, workingBadgePath: "" });
        } else {
            setSubmit({ workingBadgePath: "" });
        }
    }, [getModules, filter]);

    useEffect(() => {
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_STUDENT_SUBMIT);
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_MODULEID, submit.moduleId ?? "");
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_BADGEID, submit.badgeId ?? "");
        searchParams.delete(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID);
        setSearchParams(searchParams);
    }, [submit, searchParams, setSearchParams]);

    const getSubmitSelectFieldDefinitions = () => {
        const modules = getModules().map(_ => {
            let selectItem: SelectItem = {
                id: _.moduleId,
                name: _.moduleName
            }
            return selectItem;
        });

        if (filter.moduleId !== undefined && modules?.find(_ => _.id === filter.moduleId) === undefined) {
            setFilter({ ...filter, moduleId: undefined });
        }

        studentSubmitSelectFieldDefinitions.find(_ => _.id === "moduleId")!.values = modules;
        studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.disabled = true;

        if (filter.moduleId !== undefined) {
            const module = moduleAssignments?.find(_ => _.moduleId === filter.moduleId);
            const badges = module?.badges.filter(_ => _.submissionDate === undefined || _.canResubmit === true).map(_ => {
                let selectItem: SelectItem = {
                    id: _.badgeId,
                    name: (_.canResubmit === true ? `${_.badgeName} (Resubmit)` : _.badgeName) ?? ''
                }
                return selectItem;
            });

            if (filter.badgeId !== undefined && badges?.find(_ => _.id === filter.badgeId) === undefined) {
                setFilter({ ...filter, badgeId: undefined });
            }

            studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.values = badges;
            studentSubmitSelectFieldDefinitions.find(_ => _.id === "badgeId")!.disabled = !module?.isEvaluatorAvailable ?? true;;
        }

        return studentSubmitSelectFieldDefinitions;
    }

    const handleSubmit = (badgeAssignment: BadgeAssignment) => {
        setSubmitting(true);

        const badgeAssignmentRequest = { ...badgeAssignment };
        const hasWorkingBadgePath = badgeAssignmentRequest.workingBadgePath !== undefined && badgeAssignmentRequest.workingBadgePath.replaceAll(' ', '').length !== 0;
        badgeAssignmentRequest.workingBadgePath = hasWorkingBadgePath ? badgeAssignmentRequest.workingBadgePath : 'No path';

        submitStudentAssignment(badgeAssignmentRequest).then(result => {
            toast.addToast(`Great work! Session ${result.badgeName} has been submitted`, "success");

            result.moduleId = badgeAssignmentRequest.moduleId;
            updateModuleAssignments(result);

            setSubmit({ workingBadgePath: "" });
            setFilter({ ...filter, badgeId: undefined });
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to submit session`, "error");
            setSubmitting(false);
        });
    }

    const handleDismiss = () => {
        setDismissing(true);

        dismissStudentAssignment(submit.badgeId!).then(result => {
            toast.addToast(`Session ${result.badgeName} has been dismissed`, "success");

            result.moduleId = submit.moduleId;
            updateModuleAssignments(result);

            setSubmit({ workingBadgePath: "" });
            setFilter({ ...filter, badgeId: undefined });
            setDismissing(false);
        }).catch(() => {
            toast.addToast(`Unable to dismiss session`, "error");
            setDismissing(false);
        });
    }

    const buildEvaluatorWarning = () => {
        const selectedModule = moduleAssignments?.find(_ => _.moduleId === filter.moduleId);
        const availableModule = getModules().find(_ => _.moduleId === filter.moduleId);
        if (selectedModule !== undefined && availableModule !== undefined && selectedModule.isEvaluatorAvailable === false) {
            return <div>
                <Alert severity="error" className={commonStyles.alert} >
                    <span>There are no active <b>evaluators</b> available to <b>review</b> your submitted session for foundation <b>{selectedModule.moduleName}</b></span>
                </Alert>
            </div>
        }
    }

    return (
        <React.Fragment>
            {getModules().length > 0 && <BaseForm
                model={filter}
                setModel={setFilter}
                fields={getSubmitSelectFieldDefinitions()}
            />}
            {submit.badgeId !== undefined && <BaseForm
                model={submit}
                setModel={setSubmit}
                fields={studentSubmitFieldDefinitions}
                submitEntity={handleSubmit}
                submitting={submitting}
                submitButtonText='Submit'
                deleteEntity={enableDismiss ? handleDismiss : undefined}
                deleting={dismissing}
                deleteButtonText='Dismiss'
                deleteConfirmationText={`Are you sure you want to dismiss session <b>${submit.badgeName}</b>?`}
            />}
            {getModules().length === 0 && <div>
                <span>You have no sessions to <b>Submit</b>. You can look at your previous session submissions under your <b>Journal</b>.</span>
            </div>}
            {buildEvaluatorWarning()}
        </React.Fragment>
    );
}
