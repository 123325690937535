import { useEffect, useState } from "react";
import BaseForm from "../../../../component/form/base-form";
import { dashboardManagerFilterFieldDefinitions } from "./dashboard-manager-filter-definition";
import { Cohort } from "../../../-education/model/cohort";
import { Module } from "../../../-education/model/module";
import { SelectItem } from "../../../../component/form/select-item";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { DashboardManagerFilter } from "../../model/dashboard-manger-filter";
import { getCohortModules, getCohorts } from "../../../-education/service/cohort-service";
import { getStudents } from "../../../-education/service/student-service";
import { User } from "../../../-account/model/user";

export default function DashboardManagerFilterHander({
    filter,
    setFilter
}: {
    filter: DashboardManagerFilter,
    setFilter: React.Dispatch<React.SetStateAction<DashboardManagerFilter>>
}) {
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedCohortId, setSelectedCohortId] = useState<number | undefined>(undefined);
    const [cohorts, setCohorts] = useState<Cohort[]>([]);
    const [modules, setModules] = useState<Module[]>([]);
    const [students, setStudents] = useState<User[]>([]);

    useEffect(() => {
        setLoading(true);
        getCohorts().then(result => {
            setCohorts(result ?? []);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (selectedCohortId !== undefined) {
            setLoading(true);

            const getCohortModulesCall = getCohortModules(selectedCohortId);
            const getStudentsCall = getStudents(selectedCohortId);

            Promise.all([getCohortModulesCall, getStudentsCall]).then(result => {
                setModules(result[0]);
                setStudents(result[1]);

                setLoading(false);
            })
        }
    }, [selectedCohortId]);

    const handleFilterUpdate = (filterUpdate: DashboardManagerFilter) => {
        setSelectedCohortId(filterUpdate.cohortId);

        if (filterUpdate.cohortId !== selectedCohortId) {
            setFilter({
                cohortId: filterUpdate.cohortId,
                moduleId: undefined,
                studentId: undefined
            });
            setModules([]);
            setStudents([]);
        } else {
            setFilter(filterUpdate);
        }
    }

    const getFieldDefinitions = (): FieldDefinition[] => {

        const cohortList = cohorts.map(_ => {
            let selectItem: SelectItem = {
                id: _.id,
                name: _.name
            }
            return selectItem;
        });
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "cohortId")!.values = cohortList;
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = loading;

        const moduleList: SelectItem[] = []
        moduleList.push({
            id: 0,
            name: 'All'
        });
        modules?.forEach(_ => {
            moduleList.push({
                id: _.moduleId,
                name: _.title
            })
        });
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "moduleId")!.values = moduleList;
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "moduleId")!.disabled = loading || filter.cohortId === undefined;

        const studentList: SelectItem[] = []
        studentList.push({
            id: 0,
            name: 'All'
        });
        students?.forEach(_ => {
            studentList.push({
                id: _.id,
                name: _.name
            })
        });
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "studentId")!.values = studentList;
        dashboardManagerFilterFieldDefinitions.find(_ => _.id === "studentId")!.disabled = loading || filter.cohortId === undefined;

        return dashboardManagerFilterFieldDefinitions;
    }

    return (
        <BaseForm
            name="Dashboard manager filter"
            model={filter}
            setModel={handleFilterUpdate}
            fields={getFieldDefinitions()}
        />
    );
}
