import { FieldDefinition } from "../../../../component/form/field-definition";

export const studentUpdateFieldDefinitions: FieldDefinition[] = [
    {
        id: "workingBadgePath",
        name: "Session path",
        type: "text",
        required: true,
        disabled: false
    }
];