import { SelectItem } from "../../form/select-item";

export enum YesNo {
    All = 1,
    No = 2,
    Yes = 3
}

export const yesNoOptions = () => {
    const options = Object.values(YesNo).filter((v) => !isNaN(Number(v))) as number[];
    return options.map(_ => {
        let selectItem: SelectItem = {
            id: _,
            name: YesNo[_].replace('_', ' ')
        }
        return selectItem;
    });
}