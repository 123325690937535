import { Module } from "../../../../section/-education/model/module";
import { YesNo } from "../../enum/yes-no";

export interface ModuleMultiSelectFilterModel {
    courseLevel?: number | undefined,
    lockCourseLevel?: boolean | undefined,
    hideCourseLevel?: boolean | undefined,

    moduleCategory?: number | undefined,
    lockModuleCategory?: boolean | undefined,
    hideModuleCategory?: boolean | undefined,

    moduleSearch?: string | undefined,
    lockModuleSearch?: boolean | undefined,
    hideModuleSearch?: boolean | undefined,

    modulesSelected?: YesNo | undefined,
    lockModulesSelected?: boolean | undefined,
    hideModulesSelected?: boolean | undefined,

    excludeModules?: string[] | undefined
}

export const filterModules = (filter: ModuleMultiSelectFilterModel, modules: Module[], selectedModules: Module[]) => {
    return modules
        .filter(module =>
            (filter.excludeModules === undefined ||
                filter.excludeModules.filter(excludedModuleId => excludedModuleId === module.moduleId).length === 0) &&
            (filter.courseLevel === undefined ||
                filter.courseLevel === -1 || module.courseLevelId === filter.courseLevel) &&
            (filter.moduleCategory === undefined ||
                filter.moduleCategory === -1 || module.categoryId === filter.moduleCategory) &&
            (filter.moduleSearch === undefined ||
                module.moduleId.toLowerCase().includes(filter.moduleSearch.toLowerCase()) || module.title.toLowerCase().includes(filter.moduleSearch.toLowerCase())) &&
            (filter.modulesSelected === undefined ||
                filter.modulesSelected === YesNo.All ||
                (filter.modulesSelected === YesNo.No && selectedModules.find(_ => _.moduleId === module.moduleId) === undefined) ||
                (filter.modulesSelected === YesNo.Yes && selectedModules.find(_ => _.moduleId === module.moduleId) !== undefined))
        );
}