import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_SURVEY_SUBMIT_ID } from "../../../infrastructure/route";
import { SurveySubmit } from "../model/survey-submit";
import { getSurveySubmit } from "../service/survey-submit-service";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(tz)

export const SURVEY_TAB_STATE: string = "SURVEY_TAB_STATE";
export const SURVEY_TAB_STATE_VIEW: string = "SURVEY_TAB_STATE_VIEW";

export default function SurveySubmitView() {
    const { surveySubmitId } = useParams();

    const [tab, setTab] = useState<string>(localStorage.getItem(SURVEY_TAB_STATE) ?? SURVEY_TAB_STATE_VIEW);
    const [loading, setLoading] = useState<boolean>(true);
    const [surveySubmit, setSurveySubmit] = useState<SurveySubmit | undefined>();

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_SURVEY_SUBMIT_ID, value: surveySubmit?.surveyTitle ?? '' });
    }, [cache, surveySubmit]);

    useEffect(() => {
        setLoading(true);
        getSurveySubmit(Number(surveySubmitId)).then(result => {
            setSurveySubmit(result);
            setLoading(false);
        })
    }, [surveySubmitId]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(SURVEY_TAB_STATE, newTab);
        setTab(newTab);
    }

    const getSubmitDate = (submitDate?: string | undefined) => {
        const timeZone = dayjs.tz.guess();
        const local = dayjs.utc(submitDate).tz(timeZone);
        return dayjs(local).format('YYYY-MM-DD HH:mm:ss');
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label="View" value={SURVEY_TAB_STATE_VIEW} />
                    </TabList>
                </Box>
                <TabPanel value={SURVEY_TAB_STATE_VIEW}>
                    <h2>{surveySubmit?.surveyTitle}</h2>
                    <p><b>{surveySubmit?.name}</b></p>
                    <p><b>{surveySubmit?.phoneNumber}</b></p>
                    <p><b>{surveySubmit?.emailAddress}</b></p>
                    <p><b>{getSubmitDate(surveySubmit?.submitDate)}</b></p>
                    <br />
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '50%' }}><b>Question</b></TableCell>
                                    <TableCell sx={{ width: '50%' }}><b>Answer</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {surveySubmit?.answers.map((_) => (
                                    <TableRow key={_.questionSequence}>
                                        <TableCell>Q{_.questionSequence}: {_.question}</TableCell>
                                        <TableCell>{_.answer}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
