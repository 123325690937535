import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ViewLoader from "../../../component/misc/view-loader";
import { Evaluator } from "../model/evaluator";
import EvaluatorModuleAssign from "./-evaluator-module/evaluator-module-assign";
import { getEvaluator } from "../service/evaluator-service";
import { PARAMETER_EVALUATOR_ID } from "../../../infrastructure/route";
import { useCache } from "../../../context/cache/cache-provider";

export const EVALUATOR_TAB_STATE: string = "EVALUATOR_TAB_STATE";
export const EVALUATOR_TAB_STATE_MODULES: string = "EVALUATOR_TAB_STATE_MODULES";

export default function EvaluatorView() {
    const { evaluatorId } = useParams();
    const newModel = evaluatorId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(EVALUATOR_TAB_STATE) ?? EVALUATOR_TAB_STATE_MODULES);
    const [loading, setLoading] = useState<boolean>(true);
    const [evaluator, setEvaluator] = useState<Evaluator>({
        evaluatorId: 0,
        evaluatorName: "",
        availability: 0,
        metaRating: 0
    });

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_EVALUATOR_ID, value: evaluator.evaluatorName });
    }, [cache, evaluator]);

    useEffect(() => {
        setLoading(true);
        getEvaluator(Number(evaluatorId)).then(result => {
            setEvaluator(result)
            setLoading(false);
        });
    }, [evaluatorId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(EVALUATOR_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label="Assign modules" value={EVALUATOR_TAB_STATE_MODULES} />
                    </TabList>
                </Box>
                <TabPanel value={EVALUATOR_TAB_STATE_MODULES}>
                    <EvaluatorModuleAssign evaluator={evaluator} />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
