import { FieldDefinition } from "../../../../../component/form/field-definition";

export const badgeFieldDefinitions: FieldDefinition[] = [
    {
        id: "moduleId",
        name: "Module Id",
        type: "text",
        required: true,
        disabled: true
    },
    {
        id: "sequence",
        name: "Sequence",
        type: "number",
        required: true,
        disabled: false,
        min: 1
    },
    {
        id: "title",
        name: "Title",
        type: "text",
        required: true,
        disabled: false
    },
    {
        id: "instruction",
        name: "Instruction",
        type: "text-area",
        required: false,
        disabled: false
    },
    {
        id: "estimatedTime",
        name: "Estimated time (h)",
        type: "number",
        adornment: "hours",
        required: false,
        disabled: false,
        min: 1,
    },
    {
        id: "badgeFolder",
        name: "Badge folder",
        type: "text",
        required: false,
        disabled: false
    },
    {
        id: "allowResubmit",
        name: "Allow resubmit",
        type: "boolean",
        required: false,
        disabled: false
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    }
];