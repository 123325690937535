import { Role } from "../section/enum/role";
import { RouteCategory } from "./route-category";

export const hasCategoryAccess = (routeCategory: RouteCategory, userRoles: Role[]): boolean => {
    return routeCategory.routes.filter(_ => hasRouteAccess(_.roles, userRoles)).length > 0;
}

export const hasRouteAccess = (routeRoles: Role[], userRoles: Role[]): boolean => {
    return routeRoles.length === 0 || routeRoles.some(_ => userRoles.includes(_))
}

export const isPublic = (): boolean => {
    return window.location.pathname.startsWith('/public/');
}