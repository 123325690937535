import React, { useState } from "react";
import controlStyles from "../../../style/crud-control.module.css";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useToast } from "../../../../context/toast/toast-provider";
import { Survey, SurveyValidationError } from "../../model/survey";
import { isAnswerOptionType, SurveyQuestion, QuestionValidationError } from "../../model/survey-question";
import { addSurvey, deleteSurvey, updateSurvey } from "../../service/survey-service";
import { surveyFieldDefinitions } from "./survey-field-definition";
import { AnswerType } from "../../../enum/answer-type";
import { Button } from "@mui/material";
import { EXAMINE_SURVEY_HANDLE_EDIT, EXAMINE_SURVEY_HANDLE_LIST, PARAMETER_SURVEY_ID, PUBLIC_SURVEY } from "../../../../infrastructure/route";
import { useDialog } from "../../../../context/dialog/dialog-provider";
import { DialogType, informationText, warningText } from "../../../../context/dialog/dialog-context-type";
import SurveyHandlerQuestionContent from "./survey-handler-question-content";
import BaseForm from "../../../../component/form/base-form";

export default function SurveyHandler({
    newModel,
    survey,
    setSurvey,
}: {
        newModel: boolean,
        survey: Survey,
        setSurvey: React.Dispatch<React.SetStateAction<Survey>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [questionValidationErrors, setQuestionValidationErrors] = useState<QuestionValidationError[]>([]);
    const [surveyValidationErrors, setSurveyValidationErrors] = useState<any | undefined>([]);
    const [selectedQuestionSequence, setSelectedQuestionSequence] = useState<number | undefined>();
    const [questions, setQuestions] = useState<SurveyQuestion[]>(newModel ? [{
        sequence: 1,
        text: "",
        answerRequired: true,
        answerType: AnswerType.Text,
        answers: []
    }] : survey.questions);

    const toast = useToast();
    const navigate = useNavigate();
    const dialog = useDialog();

    const updateQuestions = (question: SurveyQuestion) => {
        const updateQuestions = [...questions ?? []].filter(_ => _.sequence !== question.sequence);
        updateQuestions.push(question);
        setQuestions(updateQuestions);
    }

    const addQuestion = (sequence: number) => {
        const newQuestion = {
            sequence: sequence + 1,
            text: "",
            answerType: AnswerType.Text,
            answerRequired: true,
            answers: []
        }

        const questionUpdates = [...questions];
        questionUpdates.forEach(_ => {
            if (_.sequence >= sequence + 1) {
                _.sequence = _.sequence + 1;
            }
        });
        questionUpdates.push(newQuestion);
        setQuestions(questionUpdates);
    }

    const moveQuestion = (sequence: number, newSequence: number) => {
        if (newSequence >= 1 && newSequence <= questions.length) {
            const question = questions.find(_ => _.sequence === sequence)!;
            const switchQuestion = questions.find(_ => _.sequence === newSequence)!;

            question.sequence = newSequence;
            switchQuestion.sequence = sequence;

            const questionUpdates = [...questions].filter(_ => _.sequence !== sequence && _.sequence !== newSequence);
            questionUpdates.push(question);
            questionUpdates.push(switchQuestion);

            setQuestions(questionUpdates);
            setSelectedQuestionSequence(newSequence);
            validate(questionUpdates);
        }
    }

    const removeQuestion = (sequence: number) => {
        const questionUpdates = [...questions].filter(_ => _.sequence !== sequence);

        questionUpdates.forEach(_ => {
            if (_.sequence > sequence) {
                _.sequence = _.sequence - 1;
            }
        });

        setQuestions(questionUpdates);
        validate(questionUpdates);
    }

    const handleQuestionSelect = (questionSequence: number) => {
        if (questionSequence !== selectedQuestionSequence) {
            setSelectedQuestionSequence(questionSequence);
        } else {
            setSelectedQuestionSequence(undefined);
        }
    }
    const openSurvey = () => {
        window.open(PUBLIC_SURVEY.replace(PARAMETER_SURVEY_ID, `${survey.id}`), "_blank")
    }

    const updateWarnings = () => {
        return survey.hasSubmits === true ? ['There are <b>submits</b> for this <b>survey</b>, updating it can lead to older submit <b>lose thier releavans</b>.'] : undefined;
    }

    const validate = (questions: SurveyQuestion[]) => {
        const invalidSurveyFields = [] as SurveyValidationError[];
        if (survey.title.trim() === '') {
            invalidSurveyFields.push({
                field: "title",
                error: 'Title is required'
            });
        }

        if (survey.description.trim() === '') {
            invalidSurveyFields.push({
                field: "description",
                error: 'Description is required'
            });
        }

        let errorObject = {}
        invalidSurveyFields.forEach(_ => {
            errorObject = { ...errorObject, [_.field]: `${_.error}` };
        })

        const invalidQuestions = [] as QuestionValidationError[];
        if (questions.length > 0) {
            questions.forEach(_ => {
                if (_.text.trim().length === 0) {
                    invalidQuestions.push({
                        sequence: _.sequence,
                        error: 'Question text is required'
                    });
                } else if (isAnswerOptionType(_.answerType) && _.answers.length < 2) {
                    invalidQuestions.push({
                        sequence: _.sequence,
                        error: 'At least two answer options are required'
                    });
                } else if (isAnswerOptionType(_.answerType) && _.answers.filter(a => a.text.length === 0).length > 0) {
                    invalidQuestions.push({
                        sequence: _.sequence,
                        error: 'Answer option text is required'
                    });
                }
            });
        } else {
            invalidQuestions.push({
                sequence: 0,
                error: 'At least one question is required'
            });
        }

        setQuestionValidationErrors(invalidQuestions);
        setSurveyValidationErrors(invalidSurveyFields.length > 0 ? errorObject : undefined);

        return invalidQuestions.length === 0 && invalidSurveyFields.length === 0;
    }

    const handleSubmit = async () => {
        setSubmitting(true);

        survey.questions = questions;
        if (newModel) {
            addSurvey(survey).then(result => {
                toast.addToast(`Survey ${result.title} created`, "success");
                setSurvey(result);
                setSubmitting(false);

                navigate(EXAMINE_SURVEY_HANDLE_EDIT.replace(PARAMETER_SURVEY_ID, `${result.id}`));
            }).catch(() => {
                toast.addToast(`Unable to create survey`, "error");
                setSubmitting(false);
            });
        } else {
            const warnings = updateWarnings();
            let update = true;
            if ((warnings?.length ?? 0) > 0) {
                update = await dialog.openDialog(DialogType.CONFIRM, "Are you sure?",
                    [
                        informationText(`Are you sure you want to update "<b>${survey.title}</b>" survey?`)
                    ].concat((warnings !== undefined ? warnings.map(_ => warningText(_)) : [])));
            }
            
            if (update === true) {
                updateSurvey(survey).then(result => {
                    toast.addToast(`Survey ${result.title} updated`, "success");
                    setSurvey(result);
                    setSubmitting(false);
                }).catch(() => {
                    toast.addToast(`Unable to update survey`, "error");
                    setSubmitting(false);
                });
            } else {
                setSubmitting(false);
            }
        }
    }

    const deleteWarnings = () => {
        return survey.hasSubmits === true ? ['There are <b>submits</b> for this <b>survey</b>, deleting it will also <b>delete submits</b>.'] : undefined;
    }

    const handleDelete = async () => {
        if (!newModel) {
            const warnings = deleteWarnings();
            const choice = await dialog.openDialog(DialogType.CONFIRM, "Are you sure?",
                [
                    informationText(`Are you sure you want to delete "<b>${survey.title}</b>" survey?`)
                ].concat((warnings !== undefined ? warnings.map(_ => warningText(_)) : [])));

            if (choice === true) {
                setDeleting(true);
                deleteSurvey(Number(survey.id)).then(() => {
                    toast.addToast(`Survey ${survey.title} created`, "success");
                    setDeleting(false);
                    navigate(EXAMINE_SURVEY_HANDLE_LIST);
                }).catch(() => {
                    toast.addToast(`Unable to delete survey`, "error");
                    setDeleting(false);
                });
            }
        }
    }

    return (
        <div style={{ paddingBottom: '3rem' }} >
            <div className={controlStyles.top}>
                <div className={controlStyles.control} >
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => addQuestion(questions.length)}
                    >
                        Add question
                    </Button>
                    {newModel === false && <Button
                        variant="outlined"
                        startIcon={<OpenInNewIcon />}
                        onClick={() => openSurvey()}
                    >
                        Open survey
                    </Button>}
                    {newModel === false && <p>{`${window.location.origin}${PUBLIC_SURVEY.replace(PARAMETER_SURVEY_ID, `${survey.id}`)}`}</p>}
                </div>
            </div>
            <BaseForm
                name={`${survey.title}`}
                model={survey}
                setModel={setSurvey}
                fields={surveyFieldDefinitions}
                knownValidationErrors={surveyValidationErrors}
            />
            {questions.sort((a, b) => a.sequence - b.sequence).map(_ => {
                return (
                    <SurveyHandlerQuestionContent
                        key={_.sequence}
                        isExpanded={_.sequence === selectedQuestionSequence}
                        question={_}
                        validationError={questionValidationErrors.find(error => error.sequence === _.sequence)?.error}
                        setQuestion={updateQuestions}
                        handleQuestionSelect={handleQuestionSelect}
                        moveQuestion={moveQuestion}
                        removeQuestion={removeQuestion}
                    />
                );
            })}
            {questionValidationErrors.filter(_ => _.sequence === 0).length > 0 && <div>
                {questionValidationErrors.filter(_ => _.sequence === 0).map(_ => {
                    return <p key={_.sequence} style={{ color: 'var(--color-error)' }} >{_.error}</p>
                })}
            </div>}
            <div style={{marginTop: '1rem'}} >
                <LoadingButton
                    sx={{ float: "right" }}
                    id={`save-button`}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    loading={submitting}
                    disabled={submitting || deleting}
                    onClick={() => {
                        const isValid = validate(questions);
                        if (isValid === true) {
                            handleSubmit();
                        }
                    }}
                >
                    Save
                </LoadingButton>
                {!newModel && <LoadingButton
                    sx={{ float: "right", marginRight: "1rem" }}
                    id={`delete-button`}
                    loadingPosition="start"
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                    loading={deleting}
                    disabled={submitting || deleting}
                    onClick={() => handleDelete()}
                >
                    Delete
                </LoadingButton>}
            </div>
        </div>
    );
}
