import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse, handleResponseNoContent } from "../../util/api/response-handler";
import { Badge } from "../model/badge";

export function getBadges(moduleId: string): Promise<Badge[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/list/${moduleId}`, requestOptions)
        .then(response => {
            return handleResponse<Badge[]>(response)
        });
}

export function getBadge(badgeId: string): Promise<Badge> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/${badgeId}`, requestOptions)
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function addBadge(badge: Badge): Promise<Badge> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(badge)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge`, requestOptions)
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function updateBadge(badge: Badge): Promise<Badge> {
    const requestOptions = {
        method: "PUT",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(badge)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge`, requestOptions)
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function deleteBadge(badgeId: string): Promise<void> {
    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/${badgeId}`, requestOptions)
        .then(response => {
            return handleResponseNoContent(response)
        });
}