import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Cohort } from "../model/cohort";
import CohortHandler from "./-cohort-handler/cohort-handler";
import { getCohort } from "../service/cohort-service";
import CohortModuleAssign from "./-cohort-modules/cohort-module-assign";
import CohortStudentAssign from "./-cohort-students/cohort-student-assign";
import CohortStatusSummary from "./-cohort-status/cohort-status-summary";
import ViewLoader from "../../../component/misc/view-loader";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_COHORT_ID } from "../../../infrastructure/route";

export const COHORT_TAB_STATE: string = "COHORT_TAB_STATE";
export const COHORT_TAB_STATE_ADD_EDIT: string = "COHORT_TAB_STATE_ADD_EDIT";
export const COHORT_TAB_STATE_STATUS: string = "COHORT_TAB_STATE_STATUS";
export const COHORT_TAB_STATE_STUDENTS: string = "COHORT_TAB_STATE_STUDENTS";
export const COHORT_TAB_STATE_MODULES: string = "COHORT_TAB_STATE_MODULES";

export default function CohortView() {
    const { cohortId } = useParams();
    const newModel = cohortId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(COHORT_TAB_STATE) ?? COHORT_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState(true);
    const [cohort, setCohort] = useState<Cohort>({
        name: "",
        year: (new Date()).getFullYear(),
        active: true,
        hasAttendance: false,
        hasPresentations: false,
        hasAssignments: false
    });

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_COHORT_ID, value: cohort.name });
    }, [cache, cohort]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);
            getCohort(Number(cohortId)).then(result => {
                setCohort(result);
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }, [cohortId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(COHORT_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={COHORT_TAB_STATE_ADD_EDIT} />
                        {!newModel && <Tab label="Status" value={COHORT_TAB_STATE_STATUS} />}
                        {!newModel && <Tab label="Students" value={COHORT_TAB_STATE_STUDENTS} />}
                        {!newModel && <Tab label="Modules" value={COHORT_TAB_STATE_MODULES} />}
                    </TabList>
                </Box>
                <TabPanel value={COHORT_TAB_STATE_ADD_EDIT}>
                    <CohortHandler
                        newModel={newModel}
                        cohort={cohort}
                        setCohort={setCohort}
                    />
                </TabPanel>
                <TabPanel value={COHORT_TAB_STATE_STATUS}>
                    <CohortStatusSummary cohort={cohort} />
                </TabPanel>
                <TabPanel value={COHORT_TAB_STATE_STUDENTS}>
                    <CohortStudentAssign cohort={cohort} />
                </TabPanel>
                <TabPanel value={COHORT_TAB_STATE_MODULES}>
                    <CohortModuleAssign cohort={cohort} />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
