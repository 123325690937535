import React, { useEffect, useState } from "react";
import { BaseList } from "../../../../../component/list/base-list";
import { DashboardManagerFilter } from "../../../model/dashboard-manger-filter";
import { CohortStudentModuleBadgeUnsubmitted } from "../../../model/cohort-student-module-badge-unsubmitted";
import { getCohortUnsubmittedBadges } from "../../../service/dashboard-manager-service";
import { columns } from "./cohort-student-module-badge-unsubmitted-list-column-definition";
import BaseOperations from "../../../../../component/operations/base-operations";

const storageKey = "DASHBOARD_MANAGER_UNSUBMITTED"

export default function CohortStudentModuleBadgeUnsubmittedList({
    filter,
    setFilter,
    expectedSubmissionDateOffsetHours
}: {
    filter: DashboardManagerFilter,
    setFilter: React.Dispatch<React.SetStateAction<DashboardManagerFilter>>,
    expectedSubmissionDateOffsetHours: number
}) {
    const [loading, setLoading] = useState(false);
    const [unsubmitted, setUnsubmitted] = useState<CohortStudentModuleBadgeUnsubmitted[]>([]);

    useEffect(() => {
        if (filter.cohortId !== undefined) {
            setLoading(true);
            getCohortUnsubmittedBadges(
                filter.cohortId,
                Number(filter.moduleId) !== 0 ? filter.moduleId : undefined,
                filter.studentId !== 0 ? filter.studentId : undefined
            ).then(result => {
                setUnsubmitted(result ?? []);
                setLoading(false);
            });
        } else {
            setUnsubmitted([]);
        }
    }, [filter]);

    return (
        <React.Fragment>
            <BaseList
                storageKey={storageKey}
                style={{ marginBottom: "1.5rem" }}
                rows={unsubmitted}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No unsubmitted badges" }}
                getRowId={(row) => `${row?.cohortId}-${row?.badgeId}-${row?.studentId}`}
                disableRowSelectionOnClick={false}
                onRowClick={(e) => setFilter({ ...filter, moduleId: e.row?.moduleId, studentId: e.row?.studentId })}
                exportName={`${storageKey}_${filter.cohortId}`}
            />
            {unsubmitted?.length > 0 && <BaseOperations
                helpInfoTexts={[
                    "You can click on a row in the list to select the <b>Module</b> and <b>Student</b>.",
                    `A badge is included if it <b>has not been submitted</b> within the expected time frame of <b>${expectedSubmissionDateOffsetHours} hours</b> since the first cohort submission if the same badge.`
                ]}
            />}
        </React.Fragment>
    );
}
