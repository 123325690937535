import styles from '../style/component.module.css';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BlockIcon from '@mui/icons-material/Block';
import { useDialog } from '../../context/dialog/dialog-provider';
import { DialogType, informationText } from '../../context/dialog/dialog-context-type';

export default function BaseOperations({
    submitButtonText,
    submitting,
    onSubmitButtonClick,

    deleteButtonText,
    deleting,
    onDeleteButtonClick,

    cancelButtonText,
    onCancelButtonClick,

    helpInfoButtonText,
    helpInfoTexts
}: {
    submitButtonText?: string | undefined,
    submitting?: boolean | undefined,
    onSubmitButtonClick?(): void | undefined,

    deleteButtonText?: string | undefined,
    deleting?: boolean | undefined,
    onDeleteButtonClick?(): void | Promise<void> | undefined,

    cancelButtonText?: string | undefined,
    onCancelButtonClick?(): void | undefined,

    helpInfoButtonText?: string | undefined,
    helpInfoTexts?: string[] | undefined
}) {
    const dialog = useDialog();

    const openHelpInformationDialog = () => {
        dialog.openDialog(DialogType.INFORMATION, "Help / Information", (helpInfoTexts ?? []).map(_ => informationText(_)));
    }

    return (
        <div className={styles.buttonList} >
            {helpInfoTexts !== undefined && <LoadingButton
                sx={{ float: "right" }}
                id={`info-button`}
                loadingPosition="start"
                startIcon={<HelpOutlineIcon />}
                variant="outlined"
                onClick={openHelpInformationDialog}
            >
                {helpInfoButtonText ?? 'Help / Info'}
            </LoadingButton>}
            {onCancelButtonClick !== undefined && <LoadingButton
                sx={{ float: "right" }}
                id={`cancel-button`}
                loadingPosition="start"
                startIcon={<BlockIcon />}
                variant="outlined"
                onClick={onCancelButtonClick}
            >
                {cancelButtonText ?? 'Cancel'}
            </LoadingButton>}
            {onDeleteButtonClick !== undefined && <LoadingButton
                sx={{ float: "right" }}
                id={`delete-button`}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="outlined"
                loading={deleting}
                disabled={submitting || deleting}
                onClick={onDeleteButtonClick}
            >
                {deleteButtonText ?? 'Delete'}
            </LoadingButton>}
            {onSubmitButtonClick !== undefined && <LoadingButton
                sx={{ float: "right" }}
                id={`save-button`}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                loading={submitting}
                disabled={submitting || deleting}
                onClick={onSubmitButtonClick}
            >
                {submitButtonText ?? 'Save'}
            </LoadingButton>}
        </div>
    )
};