import { ConfigurationSettings } from "../model/configuration-settings";
import { handleResponse } from "../util/api/response-handler";

export function getSettings(): Promise<ConfigurationSettings> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/configuration/settings`, requestOptions)
        .then(response => {
            return handleResponse<ConfigurationSettings>(response)
        });
}