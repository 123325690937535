import React, { useCallback } from "react";
import { UserMultiSelectFilterModel } from "./user-multi-select-filter-model";
import { userMultiSelectFilterFieldDefinitions } from "./user-multi-select-filter-field-definition";
import { FieldDefinition } from "../../../form/field-definition";
import BaseForm from "../../../form/base-form";

export default function UserMultiSelectFilter({
    filter,
    setFilter,
    hidden
}: {
    filter: UserMultiSelectFilterModel,
    setFilter: React.Dispatch<React.SetStateAction<UserMultiSelectFilterModel>>
    hidden?: boolean | undefined
}) {
    const handleFilter = useCallback((filterUpdate: UserMultiSelectFilterModel) => {
        setFilter(filterUpdate)
    }, [setFilter])

    const getFieldDefinitions = (): FieldDefinition[] => {
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "cohortId")!.hidden = filter.hideCohortId === true;
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "cohortId")!.disabled = filter.lockCohortId === true;

        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "gender")!.hidden = filter.hideGender === true;
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "gender")!.disabled = filter.lockGender === true;

        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "cohortAssigned")!.hidden = filter.hideCohortAssigned === true;
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "cohortAssigned")!.disabled = filter.lockCohortAssigned === true;

        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "userSearch")!.hidden = filter.hideUserSearch === true;
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "userSearch")!.disabled = filter.lockUserSearch === true;

        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "usersSelected")!.hidden = filter.hideUsersSelected === true;
        userMultiSelectFilterFieldDefinitions.find(_ => _.id === "usersSelected")!.disabled = filter.lockUsersSelected === true;

        return userMultiSelectFilterFieldDefinitions;
    }

    return (
        <React.Fragment>
            {(hidden ?? false) && <span></span>}
            {(hidden ?? false) === false && <BaseForm
                model={filter}
                setModel={(e) => handleFilter(e)}
                fields={getFieldDefinitions()}
            />}
        </React.Fragment>
    );
}