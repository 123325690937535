export function getVersion(): Promise<string> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/version`, requestOptions)
        .then(response => {
            return response.text()
        });
}