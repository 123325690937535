import { getAccessToken } from "../../util/api/access-token-handler";
import { handleResponse } from "../../util/api/response-handler";
import { AttendanceOverview } from "../model/attendance-overview";
import { AttendanceUpdate } from "../model/attendance-update";
import { Student } from "../model/student";

export function getAttendances(from: string, to: string): Promise<AttendanceOverview[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/list/${from}/${to}`, requestOptions)
        .then(response => {
            return handleResponse<AttendanceOverview[]>(response)
        });
}

export function getAttendance(day: string, cohortId: number | undefined = undefined): Promise<Student[]> {
    const requestOptions = {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        }
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/${day}?cohortId=${cohortId ?? ''}`, requestOptions)
        .then(response => {
            return handleResponse<Student[]>(response)
        });
}

export function updateAttendances(attendanceUpdate: AttendanceUpdate): Promise<Student[]> {
    const requestOptions = {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${getAccessToken()}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(attendanceUpdate)
    };

    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/${attendanceUpdate.day.format('YYYY-MM-DD') }`, requestOptions)
        .then(response => {
            return handleResponse<Student[]>(response)
        });
}